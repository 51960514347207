import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React, { useContext, useEffect, useState } from 'react';

import {
  breakerSize,
  lines,
  residentialServiceEntrances,
  secondaryLines,
  serviceEntrances,
  vegetationRemoval,
  voltage,
} from '../Context/Data';
import siteContent from '../SiteContent/SiteContent';
import HelpLink from '../Components/common/HelpLink';
import { NewConnectionServiceType, PowerLineType } from '../Context/Enums';

import DropdownQuestion from '../Components/Forms/DropdownQuestion';
import PreviousNextButtons from '../Components/common/PreviousNextButtons';
import SliderInput from '../Components/Forms/SliderInput';
import { useHistory } from 'react-router-dom';
import UserDataContext from '../Context/UserDataContext';
import { UserDataActionType } from '../Context/UserDataStateReducer';

interface IProps {
  newConnectionType: string;
}

const NewConnectionFurtherInfo: React.FC<IProps> = (props: IProps) => {
  const { newConnectionType } = props;
  const history = useHistory();
  const theme = useTheme();
  const { state: userState, dispatch } = useContext(UserDataContext);
  const lowestScreenSize = 1200;
  const matches = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  const [serviceEntrance, setServiceEntrance] = useState(
    userState.userData.electricInfo.serviceEntrance
  );
  const [serviceEntranceA, setServiceEntranceA] = useState(
    userState.userData.electricInfo.serviceEntranceA
  );
  const [selectedBreakerSize, setSelectedBreakerSize] = useState(
    userState.userData.electricInfo.breakerSize
  );
  const [secondaryVoltage, setSecondaryVoltage] = useState(
    userState.userData.electricInfo.secondaryVoltage
  );
  const [primaryOverUnder, setPrimaryOverUnder] = useState(
    userState.userData.electricInfo.primaryLineOverOrUnderground
  );
  const [primaryOverUnderLength, setPrimaryOverUnderLength] = useState(
    userState.userData.electricInfo.primaryLineLength
  );
  const [secondaryOverUnder, setSecondaryOverUnder] = useState(
    userState.userData.electricInfo.secondaryLineOverOrUnderground
  );
  const [removeVegBrushing, setRemoveVegBrushing] = useState(
    userState.userData.electricInfo.brushing
  );
  const [removeVegBrushingLength, setRemoveVegBrushingLength] = useState(
    userState.userData.electricInfo.brushingValue
  );
  const [operatingLoad, setOperatingLoad] = useState(
    userState.userData.electricInfo.operatingLoad
  );
  const [disableNext, setDisableNext] = useState(true);

  const isRequiredFieldsAllEmpty =
    !secondaryVoltage ||
    !primaryOverUnder ||
    primaryOverUnderLength === 0 ||
    !removeVegBrushing ||
    (removeVegBrushing.toLowerCase() === 'yes' &&
      removeVegBrushingLength === 0);

  const isRequiredResidentialFieldsEmpty =
    !serviceEntrance || !secondaryOverUnder;

  const isRequiredFarmFieldsEmpty = !selectedBreakerSize;

  const isRequiredSmallOilOrGeneralFieldsEmpty =
    operatingLoad === 0 || !serviceEntranceA;

  useEffect(() => {
    if (isRequiredFieldsAllEmpty) {
      setDisableNext(true);
    } else if (
      newConnectionType === NewConnectionServiceType.RESIDENTIAL &&
      isRequiredResidentialFieldsEmpty
    ) {
      setDisableNext(true);
    } else if (
      newConnectionType === NewConnectionServiceType.FARM &&
      isRequiredFarmFieldsEmpty
    ) {
      setDisableNext(true);
    } else if (
      (newConnectionType === NewConnectionServiceType.SMALL_GENERAL ||
        newConnectionType === NewConnectionServiceType.SMALL_OILFIELD) &&
      isRequiredSmallOilOrGeneralFieldsEmpty
    ) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [
    secondaryVoltage,
    primaryOverUnder,
    primaryOverUnderLength,
    removeVegBrushing,
    removeVegBrushingLength,
    serviceEntrance,
    secondaryOverUnder,
    selectedBreakerSize,
    operatingLoad,
    serviceEntranceA,
  ]);

  const handleNextPage = () => {
    dispatch({
      type: UserDataActionType.ElectricServiceInfo,
      payload: {
        electricInfo: {
          breakerSize: selectedBreakerSize,
          primaryLineOverOrUnderground: primaryOverUnder,
          secondaryLineOverOrUnderground: secondaryOverUnder,
          primaryLineLength: primaryOverUnderLength,
          secondaryVoltage: secondaryVoltage,
          serviceEntrance: serviceEntrance,
          brushing: removeVegBrushing,
          brushingValue: removeVegBrushingLength,
          serviceEntranceA: serviceEntranceA,
          operatingLoad: operatingLoad,
        },
      },
    });
    history.push(`/request-type/new-connection/site-information`);
  };

  const volts = voltage.filter((volt) => volt.flow.includes(newConnectionType));

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{ marginLeft: matches ? '8px' : 0 }}
    >
      <Grid item sx={{ marginBottom: '24px' }}>
        <Typography variant="h1">PLEASE FILL IN THE INFO BELOW</Typography>
      </Grid>
      {newConnectionType === NewConnectionServiceType.RESIDENTIAL && (
        <DropdownQuestion
          id="serviceEntrance"
          title="What is your service entrance?"
          options={residentialServiceEntrances}
          dropdownValue={serviceEntrance}
          handleValueChange={(e) => setServiceEntrance(e.target.value)}
        />
      )}
      {newConnectionType === NewConnectionServiceType.FARM && (
        <DropdownQuestion
          id="breakerSize"
          title={siteContent.requiredType.breakerSizeTitle}
          options={breakerSize}
          dropdownValue={selectedBreakerSize}
          handleValueChange={(e) => setSelectedBreakerSize(e.target.value)}
          secondaryText="Contact your electrician to determine breaker size."
          secondaryNextLine="Your minimum monthly bill is dependant on the breaker size, call us at 1-800-668-2248 for a sample bill."
        />
      )}
      {(newConnectionType === NewConnectionServiceType.SMALL_GENERAL ||
        newConnectionType === NewConnectionServiceType.SMALL_OILFIELD) && (
        <SliderInput
          id="operatingLoad"
          title={siteContent.requiredType.operatingLoadTitle}
          maxValue={
            newConnectionType === NewConnectionServiceType.SMALL_OILFIELD
              ? 75
              : 500
          }
          unit="kW"
          value={operatingLoad}
          handleValueChange={(e) => setOperatingLoad(e.target.value)}
          showWarning={false}
        />
      )}
      <DropdownQuestion
        id="secondaryVoltage"
        title={siteContent.requiredType.secondaryVoltageTitle}
        options={volts}
        dropdownValue={secondaryVoltage}
        handleValueChange={(e) => setSecondaryVoltage(e.target.value)}
        secondaryText="Secondary Voltages of '120/240 Volt 1 Phase' can only have a
              maximum operating load of 150kW."
      />
      {(newConnectionType === NewConnectionServiceType.SMALL_GENERAL ||
        newConnectionType === NewConnectionServiceType.SMALL_OILFIELD) && (
        <DropdownQuestion
          id="serviceEntranceA"
          title="Service Entrance (A)"
          options={serviceEntrances}
          dropdownValue={serviceEntranceA}
          handleValueChange={(e) => setServiceEntranceA(e.target.value)}
        />
      )}
      <DropdownQuestion
        id="primaryLineOverUnder"
        title={siteContent.requiredType.overheadUndergroundPrimaryTitle}
        options={lines}
        dropdownValue={primaryOverUnder}
        handleValueChange={(e) => setPrimaryOverUnder(e.target.value)}
        secondaryText="Primary line is the powerline connecting the existing electrical
            system to your transformer. ATCO is responsible for this line. "
        secondaryNextLine=" For lines over 225 kW, only Underground - Padmount Transformers are
            available."
        disableOption={
          newConnectionType === NewConnectionServiceType.SMALL_GENERAL &&
          operatingLoad > 225
        }
      />
      {primaryOverUnder !== '' && (
        <SliderInput
          id="primaryOverUnderLength"
          title={siteContent.requiredType.overheadUndergroundPrimaryLineTitle}
          maxValue={primaryOverUnder.includes('Underground') ? 200 : 500}
          value={primaryOverUnderLength}
          handleValueChange={(e) => setPrimaryOverUnderLength(e.target.value)}
          showWarning={
            primaryOverUnder.includes('Underground')
              ? primaryOverUnderLength >= 200
              : primaryOverUnderLength >= 500
          }
          warningType={
            primaryOverUnder.includes('Underground')
              ? PowerLineType.UNDERGROUND
              : PowerLineType.OVERHEAD
          }
        />
      )}

      {newConnectionType === NewConnectionServiceType.RESIDENTIAL && (
        <>
          <DropdownQuestion
            id="secondaryLineOverOrUnderground"
            title={siteContent.requiredType.overheadUndergroundSecondaryTitle}
            options={secondaryLines}
            dropdownValue={secondaryOverUnder}
            handleValueChange={(e) => setSecondaryOverUnder(e.target.value)}
            secondaryText="Secondary line is the powerline from your transformer to your meter.
Overhead: it is recommended that the secondary line is less than 23m from the transformer pole to the service mast. Overhead secondary line is installed by ATCO to the mast only. "
            secondaryNextLine="Underground: it is recommended that your secondary line is less than 100m. The customer is responsible for ownership and installation. For information on underground secondary, please contact an electrician as this scope is not included in the ATCO proposal."
          />
        </>
      )}

      <DropdownQuestion
        id="brushing"
        title="Is ATCO vegetation removal required for the project?"
        options={vegetationRemoval}
        dropdownValue={removeVegBrushing}
        handleValueChange={(e) => setRemoveVegBrushing(e.target.value)}
        secondaryText="Note: To save costs, customers can remove trees on their customer
            owned land as long as they meet ATCO standards. Trees to be removed
            on lands not owned by the customer must be removed by ATCO with
            landowner consent."
      />
      {removeVegBrushing.toLowerCase() === 'yes' && (
        <SliderInput
          id="brushingLength"
          title=" Length of overhead powerline that requires vegetation removal?
              (meters)"
          maxValue={500}
          value={removeVegBrushingLength}
          handleValueChange={(e) => setRemoveVegBrushingLength(e.target.value)}
        />
      )}
      <Grid item marginTop="0px">
        <Grid item container alignItems="center">
          <HelpLink label="Need help deciding?" href="/PDFs/OHUGImages.pdf" />
        </Grid>
      </Grid>

      <Grid container marginTop="16px" marginLeft="14px" paddingRight="18px">
        <PreviousNextButtons
          nextDisabled={disableNext}
          clickNextHandler={handleNextPage}
        />
      </Grid>
    </Grid>
  );
};

export default NewConnectionFurtherInfo;
