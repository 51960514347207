import { Button, ButtonProps, Typography } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import React from 'react';

interface IProps extends ButtonProps {
  isnext?: boolean;
  buttonname: string;
}

const ATCOButton: React.FC<IProps> = (props: IProps) => {
  const { isnext = true, buttonname } = props;

  return (
    <Button
      {...props}
      variant={isnext ? 'main' : 'submain'}
      startIcon={isnext === false && <KeyboardArrowLeft />}
      endIcon={isnext && <KeyboardArrowRight />}
    >
      <Typography variant="h2">{buttonname}</Typography>
    </Button>
  );
};

export default ATCOButton;
