import { Alert, ButtonProps, Snackbar, Typography } from '@material-ui/core';
import React from 'react';

interface IProps extends ButtonProps {
  open: boolean;
  content: string;
  closeMessage: () => void;
  error: boolean;
}

const SnackbarAlert: React.FC<IProps> = (props: IProps) => {
  const { open, content, closeMessage, error } = props;

  return (
    <Snackbar
      open={open}
      onClose={closeMessage}
      autoHideDuration={2500}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div>
        <Alert severity={error ? 'error' : 'success'} onClose={closeMessage}>
          <Typography variant="bodyRegular">{content}</Typography>
        </Alert>
      </div>
    </Snackbar>
  );
};

export default SnackbarAlert;
