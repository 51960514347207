import { Link, useTheme } from '@material-ui/core';
import React from 'react';
import { HelpOutlined as HelpIcon } from '@material-ui/icons';

interface IProps {
  label: string;
  href: string;
  target?: string;
}

const HelpLink: React.FC<IProps> = (props: IProps) => {
  const { label, href, target = '_blank' } = props;
  const theme = useTheme();

  return (
    <>
      <HelpIcon
        style={{
          fontSize: '1.2rem',
          color: theme.palette.primary.main,
          marginRight: '8px',
        }}
      />

      <Link variant="addEditLink" href={href} target={target}>
        {label}
      </Link>
    </>
  );
};

export default HelpLink;
