import { ICustomerInformation } from '../../Context/DataTypes';
import { Divider, Grid } from '@material-ui/core';
import ReviewField from '../common/ReviewField';

interface IProps {
  customerInfo: ICustomerInformation;
  showSiteId?: boolean;
}

const LegalLandOwnerInfo: React.FC<IProps> = ({ customerInfo, showSiteId }) => {
  return (
    <>
      <ReviewField label="Type" content={'Legal Land Owner'} />
      {showSiteId && customerInfo.siteID && (
        <ReviewField label="Site ID" content={customerInfo.siteID} />
      )}
      <ReviewField label="First Name" content={customerInfo.firstName} />
      <ReviewField label="Last Name" content={customerInfo.lastName} />
      <ReviewField label="Company Name" content={customerInfo.companyName} />
      <ReviewField label="Phone Number" content={customerInfo.phoneNumber} />
      <ReviewField label="Email Address" content={customerInfo.emailAddress} />
      <ReviewField
        label="Street Address"
        content={customerInfo.streetAddress}
      />
      <ReviewField label="City" content={customerInfo.city} />
      <ReviewField label="Postal Code" content={customerInfo.postalCode} />
      <Grid item>
        <Divider />
      </Grid>
    </>
  );
};

export default LegalLandOwnerInfo;
