import { TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { ICoordinates } from '../../Context/DataTypes';

interface IProps {
  coordinates: ICoordinates;
  setCoordinates: (e: any) => void;
}

const GPSForm: React.FC<IProps> = (props: IProps) => {
  const { coordinates, setCoordinates } = props;

  const showErrorMessage = (value: number | undefined, limit: number) => {
    return value && (value < -1 * limit || value > limit) ? true : false;
  };

  return (
    <Grid container item wrap="nowrap">
      <Grid container direction="column" spacing={1} marginRight="8px">
        <Grid item>
          <Typography variant="sidebarBold">Latitude</Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            value={coordinates.lat}
            placeholder="66.10343008"
            onChange={(e) =>
              setCoordinates({ ...coordinates, lat: e.target.value })
            }
            error={showErrorMessage(coordinates.lat, 90)}
            helperText={
              showErrorMessage(coordinates.lat, 90) &&
              'Invalid latitude value needs to be between -90 and 90'
            }
            InputProps={{
              inputProps: { min: -80 },
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="sidebarBold">Longitude</Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            value={coordinates.long}
            onChange={(e) =>
              setCoordinates({ ...coordinates, long: e.target.value })
            }
            placeholder="125.23804574"
            error={showErrorMessage(coordinates.long, 180)}
            helperText={
              showErrorMessage(coordinates.long, 180) &&
              'Invalid longitude between between -180 and 180'
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GPSForm;
