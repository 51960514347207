import { Divider, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { ICustomerInformation } from '../../Context/DataTypes';
import { ServiceRequestType } from '../../Context/Enums';
import ReviewField from '../common/ReviewField';
import UserDataContext from '../../Context/UserDataContext';
import ReviewElectricInfo from './ReviewElectricInfo';
import {
  requestTypeData,
  typeOfNewLighting,
  typeOfNewService,
} from '../../Context/Data';
import ReviewContactInfo from './ReviewContactInfo';
import ReviewLocation from './ReviewLocation';

interface IProps {
  requestType: string;
}

const ReviewForm: React.FC<IProps> = (props: IProps) => {
  const { requestType } = props;
  const { state: userState } = useContext(UserDataContext);

  const requestTypeTitle = requestTypeData.find(
    (req) => req.key === userState.userData.requestInfo.requestType
  );

  const serviceTypeTitle = typeOfNewService.find(
    (serv) => serv.key === userState.userData.requestInfo.serviceType
  );

  const poleTypeTitle = typeOfNewLighting.find(
    (serv) => serv.key === userState.userData.requestInfo.newLighting.poleType
  );
  return (
    <Grid container item direction="column">
      <Grid item container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between" marginTop="16px">
          <Grid item>
            <Typography variant="bodyBold">Request Info</Typography>
          </Grid>
        </Grid>
        <ReviewField
          label="Request Type"
          content={requestTypeTitle ? requestTypeTitle.title : ''}
        />
        {serviceTypeTitle && (
          <ReviewField label="Service Type" content={serviceTypeTitle.title} />
        )}
        <ReviewField
          label="Less Than One Year Service"
          content={
            userState.userData.requestInfo.lessThanOneYearService ? 'Yes' : 'No'
          }
        />
        <ReviewField
          label="On Lands of a First Nation"
          content={
            userState.userData.requestInfo.onFirstNationLand ? 'Yes' : 'No'
          }
        />
        <Grid item>
          <Divider />
        </Grid>
      </Grid>
      {(requestType === ServiceRequestType.NEW_CONNECTION ||
        requestType === ServiceRequestType.NEW_LIGHTING) && (
        <Grid item container direction="column" spacing={2} marginTop="8px">
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="bodyBold">Electric Info</Typography>
            </Grid>
          </Grid>

          {requestType === ServiceRequestType.NEW_CONNECTION && (
            <ReviewElectricInfo
              serviceData={userState.userData.electricInfo}
              requestType={requestType}
              serviceType={userState.userData.requestInfo.serviceType}
              poleType={userState.userData.requestInfo.newLighting.poleType}
            />
          )}
          {requestType === ServiceRequestType.NEW_LIGHTING && poleTypeTitle && (
            <ReviewField label="Pole Type" content={poleTypeTitle.title} />
          )}
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
      )}
      <Grid item container direction="column" spacing={2} marginTop="8px">
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="bodyBold">Location Info</Typography>
          </Grid>
        </Grid>
        <ReviewField
          label="Location Type"
          content={userState.userData.locationInfo.locationType}
        />
        <ReviewLocation location={userState.userData.locationInfo} />
        <Grid item>
          <Divider />
        </Grid>
      </Grid>

      <Grid item container direction="column" spacing={2} marginTop="8px">
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="bodyBold">Customer</Typography>
          </Grid>
        </Grid>
        {userState.userData.customerInfo.map(
          (customerInfo: ICustomerInformation) => (
            <ReviewContactInfo
              customerInfo={customerInfo}
              requestType={requestType}
            />
          )
        )}
      </Grid>
    </Grid>
  );
};

export default ReviewForm;
