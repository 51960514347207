import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const initBeforeUnload = (showPrompt: boolean) => {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    if (showPrompt) {
      const e = event || window.Event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
    }
    return "";
  };
};

export default function useRefreshPrompt(
  shouldUse: boolean
): React.Dispatch<React.SetStateAction<boolean>> {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(shouldUse);

  window.onload = function () {
    initBeforeUnload(showPrompt);
    history.push("/");
  };

  useEffect(() => {
    initBeforeUnload(showPrompt);
  }, [showPrompt]);

  return setShowPrompt;
}
