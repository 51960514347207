import { useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import SelectionCard from '../Components/SelectionCard';
import { typeOfNewLighting } from '../Context/Data';

interface IProps {
  newServiceType: string;
  setNewServiceType: React.Dispatch<React.SetStateAction<string>>;
}

const NewLightingRequiredInfo: React.FC<IProps> = (props: IProps) => {
  const { newServiceType, setNewServiceType } = props;
  const theme = useTheme();
  const lowestScreenSize = 1200;
  const matches = useMediaQuery(theme.breakpoints.down(lowestScreenSize));

  return (
    <Grid
      container
      alignItems="center"
      style={{ marginLeft: matches ? '16px' : '0px' }}
      paddingLeft="8px"
    >
      <Grid item container>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item>
            <Grid container direction="column" spacing={2}>
              {typeOfNewLighting.map((light) => (
                <Grid
                  item
                  key={light.title}
                  onClick={() => {
                    setNewServiceType(light.key);
                  }}
                >
                  <SelectionCard
                    title={light.title}
                    selected={newServiceType === light.key}
                    description={light.description}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewLightingRequiredInfo;
