import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import { ICustomerInformation } from '../../Context/DataTypes';
import ReviewField from '../common/ReviewField';
import { customerTypeOptions } from '../../Context/Data';

interface IProps {
  customerInfo: ICustomerInformation;
}

const ATCOExpeditorContactInfo: React.FC<IProps> = ({ customerInfo }) => {
  const customerContactTitle = customerTypeOptions.find(
    (cust) => cust.key === customerInfo.contactType
  );
  return (
    <>
      <ReviewField
        label="Type"
        content={customerContactTitle ? customerContactTitle.title : ''}
      />
      <ReviewField label="First Name" content={customerInfo.firstName} />
      <ReviewField label="Last Name" content={customerInfo.lastName} />
      <Grid item>
        <Divider />
      </Grid>
    </>
  );
};

export default ATCOExpeditorContactInfo;
