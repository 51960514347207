import { Box, Checkbox, Grid, Link, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import SiteContent from '../SiteContent/SiteContent';
import ATCOButton from '../Components/common/ATCOButton';
import UserDataContext from '../Context/UserDataContext';
import { UserDataActionType } from '../Context/UserDataStateReducer';
import { useHistory } from 'react-router-dom';

const Welcome: React.FC = () => {
  const { state: userState, dispatch } = useContext(UserDataContext);
  const [acceptTerms, setAcceptTerms] = useState(
    userState.acceptTermsAndConditions
  );
  const history = useHistory();

  useEffect(() => {
    const userId = Math.floor((Date.now() + Math.random()) / 1000);
    dispatch({
      type: UserDataActionType.UserUniqueId,
      payload: { uniqueId: userId.toString() },
    });
  }, []);

  const handleNextPage = () => {
    dispatch({
      type: UserDataActionType.AcceptTermsAndConditions,
      payload: {
        acceptTermsAndConditions: acceptTerms,
      },
    });
    history.push('/request-type');
  };

  return (
    <Grid container item direction="column" spacing={1} paddingLeft="24px">
      <Grid item>
        <Typography variant="h1">{SiteContent.welcome.title}</Typography>
      </Grid>
      <Grid item paddingTop="0" marginTop="32px">
        <Typography variant="bodyBold">
          {SiteContent.welcome.titleSmall}
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarBold">
          {SiteContent.welcome.listTitle1}
          {'. '}
          <Box display="inline">
            <Typography variant="sidebarRegular">
              {SiteContent.welcome.listTitle1Description}
            </Typography>
          </Box>
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarBold">
          {SiteContent.welcome.listTitle2}{' '}
          <Box display="inline" flexWrap="nowrap">
            <Typography variant="sidebarRegular">
              {SiteContent.welcome.listTitle2Description}
            </Typography>
          </Box>
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarBold" flexShrink={1}>
          {SiteContent.welcome.listTtile3}{' '}
          <Box display="inline" flexWrap="nowrap">
            <Typography variant="sidebarRegular">
              {SiteContent.welcome.listTitle3Description}
            </Typography>
          </Box>
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarBold" flexShrink={1}>
          {SiteContent.welcome.listTitle4}{' '}
          <Box display="inline" flexWrap="nowrap">
            <Typography variant="sidebarRegular">
              {SiteContent.welcome.listTitle4Description}
            </Typography>
          </Box>
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarBold" flexShrink={1}>
          {SiteContent.welcome.listTitle5}{' '}
          <Box display="inline" flexWrap="nowrap">
            <Typography variant="sidebarRegular">
              {SiteContent.welcome.listTitle5Description}
            </Typography>
          </Box>
        </Typography>
      </Grid>

      <Grid item paddingTop="0" marginTop="12px">
        <Typography variant="bodyBold">Who is this tool for?</Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarRegular">
          New rural electrical services, new extension ≤ 500m overhead and/or ≤
          200m underground. Rural connections are for all customers outside
          cities or towns, can include hamlet or villages.
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarRegular">
          Farm breaker changes - Intake of information only.{' '}
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarRegular">
          Adding yard light to an existing pole.
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarRegular">
          Installing a yard light on a new pole.
        </Typography>
      </Grid>

      <Grid item paddingTop="0" marginTop="12px">
        <Typography variant="bodyBold">Who is this tool not for?</Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Link
          variant="addEditLink"
          href="https://www.atco.com/en-ca/for-home/electricity/our-services/micro-generation.html"
          target="_blank"
        >
          Microgeneration
        </Link>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Link
          variant="addEditLink"
          href="https://www.atco.com/en-ca/for-business/electricity/products-services/oilfield-industrial-customers.html"
          target="_blank"
        >
          Oilfield and Industrial (over 75kW)
        </Link>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarRegular">
          This tool is to be used when power poles and/ or a transformer is
          required on site to provide power to a location that does not have
          power. For energize/connections only or existing services that require
          upgrading or downgrading, please call 1-800-668-2248.
        </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Typography variant="sidebarRegular">
          This tool is NOT to be used for projects in crown land, Rural
          Electrification Association (
        </Typography>
        <Link
          variant="addEditLink"
          href="/PDFs/REA_January_2019.pdf"
          target="_blank"
        >
          REA
        </Link>
        <Typography variant="sidebarRegular">
          ) projects, or salvage/removal projects.
        </Typography>
      </Grid>

      <Grid item paddingTop="0" marginTop="12px">
        <Typography variant="bodyBold">Supporting Forms: </Typography>
      </Grid>

      <Grid item marginLeft="32px" display="list-item">
        <Link
          variant="addEditLink"
          href="/PDFs/CustomerOperatingLoadBrochure.pdf"
          target="_blank"
        >
          Customer Operating Load Brochure
        </Link>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Link
          variant="addEditLink"
          href="/PDFs/CustomerRoutingGuide_V10.pdf"
          target="_blank"
        >
          Customer Routing Guide
        </Link>
      </Grid>

      <Grid item paddingTop="0" marginTop="12px">
        <Typography variant="bodyBold">Reference Links: </Typography>
      </Grid>
      <Grid item marginLeft="32px" display="list-item">
        <Link
          variant="addEditLink"
          href="https://www.atco.com/content/dam/web/for-home/electricity/egbu_guide_to_new_extensions.pdf"
          target="_blank"
        >
          Customer Guide To New Extensions
        </Link>
      </Grid>

      <Grid item marginLeft="32px" display="list-item">
        <Link
          variant="addEditLink"
          href="https://www.atco.com/content/dam/web/for-home/electricity/new-egbu-customer-metering-guide.pdf"
          target="_blank"
        >
          Customer Metering Guide
        </Link>
      </Grid>
      <Grid container wrap="nowrap" alignItems="center" marginTop="16px">
        <Grid item>
          <Checkbox
            checked={acceptTerms}
            color="primary"
            onClick={(e: any) => setAcceptTerms(e.target.checked)}
          />
        </Grid>
        <Grid item alignSelf="center">
          <Typography variant="sidebarRegular">
            I agree to the{' '}
            <Link
              variant="addEditLink"
              borderBottom="0.5px solid #0057B8"
              href="https://www.atco.com/content/dam/web/for-home/electricity/egbu-customer-tandcs.pdf"
              target="_blank"
            >
              terms and conditions
            </Link>{' '}
            provided by ATCO and by submitting this document, I acknowledge this
            information provided to be accurate. The price generated is not
            guaranteed until verified and signed off by an ATCO representative.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        marginTop="16px"
        marginBottom="32px"
        sx={{ flexDirection: 'row-reverse' }}
      >
        <ATCOButton
          buttonname="Start Quote"
          onClick={handleNextPage}
          disabled={acceptTerms === false}
        />
      </Grid>
    </Grid>
  );
};

export default Welcome;
