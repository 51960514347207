import { MenuItem, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React, { useState } from 'react';
import { ICustomerInformation, IDropdownOption } from '../../Context/DataTypes';
import { ContactTypes } from '../../Context/Enums';
import FormInput from '../common/FormInput';
import { customerTypeOptions } from '../../Context/Data';
import { validateEmail, validatePhoneNumber } from '../helpers/util';

interface IProps {
  customer: ICustomerInformation;
  handleCustomerChange: (customer: ICustomerInformation, index: number) => void;
  requestType: string;
  index: number;
  setHasError: (hasError: boolean) => void;
}

const ContactInformationForm: React.FC<IProps> = (props: IProps) => {
  const {
    customer,
    handleCustomerChange,
    requestType,
    index,
    setHasError,
  } = props;
  const [customerInput, setCustomerInput] = useState<ICustomerInformation>(
    customer
  );

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="bodyBold">Contact Type</Typography>
      </Grid>
      <Grid item style={{ marginBottom: '16px' }}>
        <TextField
          fullWidth
          variant="outlined"
          select
          value={customerInput.contactType}
          onChange={(e) => {
            const newContactCustomer = {
              ...customerInput,
              contactType: e.target.value,
            };
            handleCustomerChange(newContactCustomer, index);
          }}
        >
          {customerTypeOptions.map((data: IDropdownOption) => (
            <MenuItem value={data.key} key={data.key} disabled={data.disabled}>
              <Typography variant="bodyRegular">{data.description}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="First Name"
            value={customerInput.firstName}
            handleOnChange={(e) => {
              const custChanges = {
                ...customerInput,
                firstName: e.target.value,
              };
              setCustomerInput(custChanges);
              handleCustomerChange(custChanges, index);
            }}
          />
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="Last Name"
            value={customerInput.lastName}
            handleOnChange={(e) => {
              const custChanges = {
                ...customerInput,
                lastName: e.target.value,
              };
              setCustomerInput(custChanges);
              handleCustomerChange(custChanges, index);
            }}
          />
        </Grid>
        {customer.contactType !== ContactTypes.ATCO_EXPEDITOR && (
          <>
            <Grid item container direction="column" spacing={1}>
              <FormInput
                label="Company Name"
                value={customerInput.companyName}
                handleOnChange={(e) => {
                  const custChanges = {
                    ...customerInput,
                    companyName: e.target.value,
                  };
                  setCustomerInput(custChanges);
                  handleCustomerChange(custChanges, index);
                }}
                showOptional
              />
            </Grid>

            <Grid item container direction="column" spacing={1}>
              <FormInput
                label="Phone Number"
                value={customerInput.phoneNumber}
                handleOnChange={(e) => {
                  const custChanges = {
                    ...customerInput,
                    phoneNumber: e.target.value,
                  };
                  setCustomerInput(custChanges);
                  handleCustomerChange(custChanges, index);
                }}
                error={validatePhoneNumber(customerInput.phoneNumber)}
                helperText={
                  validatePhoneNumber(customerInput.phoneNumber) &&
                  'Invalid Phone Number Format. Must follow format 123-456-7890 or 1234567890'
                }
              />
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <FormInput
                label="Email Address"
                value={customerInput.emailAddress}
                handleOnChange={(e) => {
                  const custChanges = {
                    ...customerInput,
                    emailAddress: e.target.value,
                  };
                  setCustomerInput(custChanges);
                  handleCustomerChange(custChanges, index);
                }}
                error={validateEmail(customerInput.emailAddress)}
                helperText={
                  validateEmail(customerInput.emailAddress) &&
                  'Invalid email address format. Must be in the form of abc@example.com'
                }
                showOptional
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ContactInformationForm;
