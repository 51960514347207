import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import RequestType from '../Pages/RequestType';
import ThankYou from '../Pages/ThankYou';
import Welcome from '../Pages/Welcome';
import SiteInformation from '../Pages/SiteInformation';
import RequiredInfo from '../Pages/RequiredInfo';
import NewConnectionFurtherInfo from '../Pages/NewConnectionFurtherInfo';
import UploadDocuments from '../Pages/UploadDocuments';
import ContactInformation from '../Pages/ContactInformation';
import Review from '../Pages/Review';

export enum RequestTypePath {
  NEW_CONNECTION = 'new-connection',
  NEW_LIGHTING = 'new-lighting',
}

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact={true} path="/thank-you" component={ThankYou} />
      <Route exact={true} path="/" component={Welcome} />
      <Route exact={true} path="/request-type" component={RequestType} />
      <Route
        exact={true}
        path="/request-type/:requestType/required-info"
        render={(props: RouteComponentProps<{ requestType: string }>) => (
          <RequiredInfo requestType={props.match.params.requestType} />
        )}
      />
      <Route
        exact={true}
        path="/request-type/new-connection/:newConnectionType/required-info"
        render={(props: RouteComponentProps<{ newConnectionType: string }>) => (
          <NewConnectionFurtherInfo
            newConnectionType={props.match.params.newConnectionType}
          />
        )}
      />
      <Route
        exact={true}
        path="/request-type/:requestType/site-information"
        render={(props: RouteComponentProps<{ requestType: string }>) => (
          <SiteInformation requestType={props.match.params.requestType} />
        )}
      />
      <Route
        exact={true}
        path="/request-type/:requestType/upload-documents"
        render={(props: RouteComponentProps<{ requestType: string }>) => (
          <UploadDocuments requestType={props.match.params.requestType} />
        )}
      />
      <Route
        exact={true}
        path="/request-type/:requestType/land-owner-info"
        render={(props: RouteComponentProps<{ requestType: string }>) => (
          <ContactInformation requestType={props.match.params.requestType} />
        )}
      />
      <Route
        exact={true}
        path="/request-type/:requestType/review"
        render={(props: RouteComponentProps<{ requestType: string }>) => (
          <Review requestType={props.match.params.requestType} />
        )}
      />
      <Route
        exact={true}
        path="/:requestType/success"
        render={(props: RouteComponentProps<{ requestType: string }>) => (
          <ThankYou requestType={props.match.params.requestType} />
        )}
      />
    </Switch>
  );
};

export default Routes;
