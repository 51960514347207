import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SiteContent from '../SiteContent/SiteContent';

interface IProps {
  voltage?: boolean;
  lineLength?: boolean;
  basic?: boolean;
  lineLengthType?: string;
  length?: number;
  breakerSize?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    outer: {
      borderColor: theme.palette.secondary.main,
    },
    inner: {
      backgroundColor: theme.palette.secondaryShadow,
    },
  })
);

const Warning: React.FC<IProps> = (props: IProps) => {
  const classes = useStyle();

  const getWarningTypography = (data: IProps) => {
    let element = <React.Fragment></React.Fragment>;
    if (data.lineLength) {
      let warningText = '';
      if (props.lineLengthType?.toLowerCase().includes('underground')) {
        warningText = `For ${props.lineLengthType?.toLowerCase()} lines greater than 200m in length please call our care team at`;
      }

      if (props.lineLengthType?.toLowerCase().includes('overhead')) {
        warningText = `For ${props.lineLengthType?.toLowerCase()} lines greater than 500m in length please call our care team at`;
      }
      element = (
        <Typography variant="bodyRegular">
          {warningText}{' '}
          <Typography variant="warning">{SiteContent.contactNumber}</Typography>{' '}
          to request the service.
        </Typography>
      );
    }

    if (data.voltage) {
      element = (
        <Typography variant="bodyRegular">
          For underground lines greater than 200m in length, please call our
          care team at{' '}
          <Typography variant="warning">{SiteContent.contactNumber}</Typography>{' '}
          if you need help to determine what this voltage is.
        </Typography>
      );
    }

    if (data.basic) {
      element = (
        <Typography variant="bodyRegular">
          Your quote may be inaccurate without this information. Please call us
          if you have any questions at{' '}
          <Typography variant="warning">{SiteContent.contactNumber}</Typography>
        </Typography>
      );
    }

    if (data.breakerSize) {
      element = (
        <Typography variant="bodyRegular">
          Your minimum monthly bill is dependent on the breaker size, call us at{' '}
          <Typography variant="warning">{SiteContent.contactNumber}</Typography>{' '}
          at for a sample bill.
        </Typography>
      );
    }
    return element;
  };

  const warning = getWarningTypography(props);

  return (
    <Box border={1} className={classes.outer}>
      <Box padding="16px" className={classes.inner}>
        {warning}
      </Box>
    </Box>
  );
};

export default Warning;
