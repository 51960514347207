import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { locationTypes } from '../Context/Data';
import SiteContent from '../SiteContent/SiteContent';
import PreviousNextButtons from '../Components/common/PreviousNextButtons';
import { useHistory } from 'react-router-dom';
import GoogleMapImage from '../Components/common/GoogleMapImage';
import GPSForm from '../Components/Forms/GPSForm';
import LeaglLandForm from '../Components/Forms/LegalLandForm';
import { ICoordinates, ILegalLand } from '../Context/DataTypes';
import UserDataContext from '../Context/UserDataContext';
import { CoordinateTypes } from '../Context/Enums';
import { UserDataActionType } from '../Context/UserDataStateReducer';

interface IProps {
  requestType: string;
}

const SiteInformation: React.FC<IProps> = (props: IProps) => {
  const { requestType } = props;

  const theme = useTheme();
  const history = useHistory();
  const { state: userState, dispatch } = useContext(UserDataContext);
  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  const [locationType, setLocationType] = useState(
    userState.userData.locationInfo.locationType || ''
  );
  const emptyLegalLand = {
    LSD: '',
    SEC: '',
    TWP: '',
    RGE: '',
    MER: '',
  };
  const [legalLand, setLegalLand] = useState<ILegalLand>(
    userState.userData.locationInfo.legalLand || emptyLegalLand
  );
  const defaultCoordinates = {
    lat: undefined,
    long: undefined,
  };
  const [gpsCoordinates, setGpsCoordinates] = useState<ICoordinates>(
    userState.userData.locationInfo.geometry || defaultCoordinates
  );

  const [disableNext, setDisableNext] = useState<boolean>(true);

  useEffect(() => {
    if (locationType === CoordinateTypes.GPS) {
      if (gpsCoordinates.lat && gpsCoordinates.long) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }
    }
    if (locationType === CoordinateTypes.LEGAL) {
      if (
        legalLand.LSD &&
        legalLand.SEC &&
        legalLand.TWP &&
        legalLand.RGE &&
        legalLand.MER
      ) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }
    }
  }, [locationType, gpsCoordinates, legalLand]);

  const handleNextPage = () => {
    dispatch({
      type: UserDataActionType.LocationInfo,
      payload: {
        locationInfo: {
          locationType: locationType || '',
          geometry:
            locationType === CoordinateTypes.GPS
              ? {
                  lat: gpsCoordinates.lat,
                  long: gpsCoordinates.long,
                }
              : {},
          legalLand:
            locationType === CoordinateTypes.LEGAL ? legalLand : emptyLegalLand,
        },
      },
    });
    history.push(`/request-type/${requestType}/upload-documents`);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      spacing={2}
      sx={{
        marginLeft: isMobile ? '8px' : '0px',
        marginBottom: isMobile ? '8px' : '44px',
      }}
    >
      <Grid item sx={{ marginBottom: '24px' }}>
        <Typography variant="h1">
          WHERE IS THE NEW SERVICE REQUIRED ?
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="bodyBold">
              {SiteContent.siteInformation.locationTitle}
            </Typography>
          </Grid>
          <Grid item container>
            <Select
              variant="outlined"
              id="typeofLocation"
              value={locationType}
              onChange={(e: any) => setLocationType(e.target.value)}
              sx={{ width: '100%' }}
            >
              {locationTypes.map((b) => (
                <MenuItem value={b.title} key={b.key}>
                  <Typography variant="bodyRegular">{b.description}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      {locationType === CoordinateTypes.GPS && (
        <>
          <GPSForm
            coordinates={gpsCoordinates}
            setCoordinates={setGpsCoordinates}
          />
          <GoogleMapImage coordinates={gpsCoordinates} />
        </>
      )}
      {locationType === CoordinateTypes.LEGAL && (
        <>
          <LeaglLandForm legalLand={legalLand} setLegalLand={setLegalLand} />
        </>
      )}

      <Grid
        container
        marginTop="16px"
        marginLeft="16px"
        paddingRight="16px"
        paddingBottom="60px"
      >
        <Box
          sx={{
            width: '100%',
            height: '62px',
            backgroundColor: '#F2A9001A',
            border: '2px solid #F2A900',
            padding: '20px',
          }}
        >
          <Typography variant="bodyRegular" fontSize="14px" fontWeight="400">
            You can proceed without the image being found.
          </Typography>
        </Box>
      </Grid>

      <Grid container marginTop="16px" marginLeft="16px" paddingRight="16px">
        <PreviousNextButtons
          nextDisabled={disableNext}
          clickNextHandler={handleNextPage}
        />
      </Grid>
    </Grid>
  );
};

export default SiteInformation;
