import { FileObject } from "material-ui-dropzone";
import React, { createContext } from "react";
import { IAttachmentPaths, ISiteDocumentData } from "./DataTypes";

interface ISiteDocumentAction {
  setSiteDocumentData: React.Dispatch<React.SetStateAction<FileObject[]>>;
  setDocumentsAvailable: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setAdditionalInformation: React.Dispatch<React.SetStateAction<string>>;
  setAttachmentPaths: React.Dispatch<React.SetStateAction<IAttachmentPaths[]>>;
  setFirebaseAttachmentPath: React.Dispatch<React.SetStateAction<string>>;
  setUploadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setUniqueID: React.Dispatch<React.SetStateAction<number | undefined>>;
  setUniquePath: React.Dispatch<React.SetStateAction<number | undefined>>;
}

interface IProps {
  children?: React.ReactNode;
}

const SiteDocumentDataContext = createContext<ISiteDocumentData>({
  files: [],
  documentsAvailable: undefined,
  additionalInformation: "",
  attachmentPaths: [],
  firebaseAttachmentPath: "",
  uploadData: false,
  uniqueID: undefined,
  uniquePath: undefined,
} as ISiteDocumentData);

const SiteDocumentActionsContext = createContext<ISiteDocumentAction>({
  setSiteDocumentData: React.useState,
  setDocumentsAvailable: React.useState,
  setAdditionalInformation: React.useState,
  setAttachmentPaths: React.useState,
  setFirebaseAttachmentPath: React.useState,
  setUploadingData: React.useState,
  setUniqueID: React.useState,
  setUniquePath: React.useState,
});

const SiteDocumentDataProvider: React.FC<IProps> = (props: IProps) => {
  const [siteDocuments, setSiteDocuments] = React.useState<FileObject[]>([]);
  const [documentsAvailable, setDocumentsAvailable] = React.useState<
    boolean | undefined
  >(undefined);
  const [
    additionalInformation,
    setAdditionalInformation,
  ] = React.useState<string>("");
  const [attachmentPaths, setAttachmentPaths] = React.useState<
    IAttachmentPaths[]
  >([]);
  const [
    firebaseAttachmentPath,
    setFirebaseAttachmentPath,
  ] = React.useState<string>("");
  const [uploadingData, setUploadingData] = React.useState<boolean>(false);
  const [uniqueID, setUniqueID] = React.useState<number | undefined>(undefined);
  const [uniquePath, setUniquePath] = React.useState<number | undefined>(
    undefined
  );

  return (
    <SiteDocumentDataContext.Provider
      value={{
        files: siteDocuments,
        documentsAvailable,
        additionalInformation,
        attachmentPaths,
        uploadData: uploadingData,
        firebaseAttachmentPath,
        uniqueID,
        uniquePath,
      }}
    >
      <SiteDocumentActionsContext.Provider
        value={{
          setSiteDocumentData: setSiteDocuments,
          setDocumentsAvailable,
          setAdditionalInformation,
          setAttachmentPaths,
          setFirebaseAttachmentPath,
          setUploadingData,
          setUniqueID,
          setUniquePath,
        }}
      >
        {props.children}
      </SiteDocumentActionsContext.Provider>
    </SiteDocumentDataContext.Provider>
  );
};

export { SiteDocumentActionsContext, SiteDocumentDataContext };

export default SiteDocumentDataProvider;
