import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import siteContent from '../../SiteContent/SiteContent';
import { PowerLineType } from '../../Context/Enums';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    outer: {
      borderColor: theme.palette.secondary.main,
    },
    inner: {
      backgroundColor: theme.palette.secondaryShadow,
    },
  })
);

interface IProps {
  warningType?: string;
}

const PowerLineWarningMessage: React.FC<IProps> = (props: IProps) => {
  const { warningType } = props;
  const classes = useStyle();

  const warningStartText = () => {
    if (warningType === PowerLineType.UNDERGROUND) {
      return `For ${PowerLineType.UNDERGROUND} lines greater than 200m in length please call our care team at`;
    }
    if (warningType === PowerLineType.OVERHEAD) {
      return `For ${PowerLineType.OVERHEAD} lines greater than 500m in length please call our care team at`;
    }
  };

  const warningEndText = () => {
    if (
      warningType === PowerLineType.UNDERGROUND ||
      warningType === PowerLineType.OVERHEAD
    ) {
      return ` to request the service.`;
    }
  };

  return (
    <Box border={1} className={classes.outer}>
      <Box padding="16px" className={classes.inner}>
        <Typography variant="bodyRegular">
          {warningStartText()}{' '}
          <Typography variant="warning">{siteContent.contactNumber}</Typography>{' '}
          {warningEndText()}
        </Typography>
      </Box>
    </Box>
  );
};

export default PowerLineWarningMessage;
