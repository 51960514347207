/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios';
import {
  ICoordinates,
  IGPSCoordinates,
  ILandLocationData,
  ILandLocationResponse,
  ILegalLandCoordinates,
  ISubmissionData,
} from '../Context/DataTypes';
import { functions } from './firebase';
import { mapElectricDataToSubmit } from '../Components/helpers/util';

const ElectricAPI = {
  getGeocodeData: async (address: string): Promise<ICoordinates> => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`
    );

    const responseData = response.data.results[0].geometry.location as {
      lat: number;
      lng: number;
    };
    return responseData;
  },

  getStaticMap: async (coords: ICoordinates): Promise<string> => {
    const coordinates = {
      lat: coords.lat,
      long: coords.long,
    } as ICoordinates;
    const urlString = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates.lat},${coordinates.long}&zoom=19&size=818x526&maptype=satellite&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`;
    const returnedResponse = await axios
      .get(urlString, {
        responseType: 'arraybuffer',
      })
      .then((response) =>
        Buffer.from(response.data, 'binary').toString('base64')
      );
    return `data:image/png;base64, ${returnedResponse}`;
  },

  getStaticMapWithBox: async (
    coordinates: ILegalLandCoordinates
  ): Promise<string> => {
    const urlString = `https://maps.googleapis.com/maps/api/staticmap?size=500x500&maptype=satellite&path=color:0xff0000ff|${coordinates.coord1[1]},${coordinates.coord1[0]}|${coordinates.coord2[1]},${coordinates.coord1[0]}|${coordinates.coord3[1]},${coordinates.coord3[0]}|${coordinates.coord4[1]},${coordinates.coord4[0]}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`;
    const returnedResponse = await axios
      .get(urlString, {
        responseType: 'arraybuffer',
      })
      .then((response) =>
        Buffer.from(response.data, 'binary').toString('base64')
      );
    return `data:image/png;base64, ${returnedResponse}`;
  },

  getLandLocationCoordinates: async (
    data: ILandLocationData
  ): Promise<ILegalLandCoordinates> => {
    let legalLandCoordinates = {} as ILegalLandCoordinates;
    try {
      const response = await axios.get(
        `https://geospatial.alberta.ca/titan/rest/services/base/alberta_township_system/MapServer/find?searchText=QS-${data.LSD}+SEC-${data.SEC}+TWP-${data.TWP}+RGE-${data.RGE}+MER-${data.MER}&f=json&layers=19&outFields=*&returnGeometry=true&sr=3857`
      );
      const responseData = response.data as ILandLocationResponse;
      const queriedData =
        responseData.features[0] !== undefined &&
        responseData.features[0].geometry !== undefined
          ? responseData.features[0].geometry.rings[0]
          : [];
      if (queriedData.length > 0) {
        legalLandCoordinates = {
          coord1: queriedData[0],
          coord2: queriedData[1],
          coord3: queriedData[2],
          coord4: queriedData[3],
          coord5: queriedData[4],
        } as ILegalLandCoordinates;
      }

      if (queriedData.length === 0) {
        legalLandCoordinates = {
          coord1: [0, 0],
          coord2: [0, 0],
          coord3: [0, 0],
          coord4: [0, 0],
          coord5: [0, 0],
        } as ILegalLandCoordinates;
      }
    } catch (error) {
      console.log(error);
    }

    return legalLandCoordinates;
  },

  submitData: async (data: ISubmissionData): Promise<any> => {
    try {
      const submitData = mapElectricDataToSubmit(data);

      const submitFormDataForQuote = functions.httpsCallable(
        'submitFormDataForQuote'
      );

      const response = await submitFormDataForQuote(submitData);

      return response.data;
    } catch (error) {
      console.error('Error submitting data:', error);
      throw error;
    }
  },

  submitFeedback: async (data: {
    rating: string;
    feedback: string;
  }): Promise<void> => {
    return await axios.post(
      'https://us-central1-electric-self-serve.cloudfunctions.net/submitFeedback',
      { data }
    );
  },
};

export default ElectricAPI;
