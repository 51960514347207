import { ILocationInfo } from '../../Context/DataTypes';
import ReviewField from '../common/ReviewField';
import { CoordinateTypes } from '../../Context/Enums';

interface IProps {
  location: ILocationInfo;
}

const ReviewLocation: React.FC<IProps> = ({ location }) => {
  return (
    <>
      {location.locationType === CoordinateTypes.LEGAL && location.legalLand && (
        <>
          <ReviewField label="LSD" content={location.legalLand.LSD} />
          <ReviewField label="Section" content={location.legalLand.SEC} />
          <ReviewField label="Township" content={location.legalLand.TWP} />
          <ReviewField label="Range" content={location.legalLand.RGE} />
          <ReviewField label="Meridian" content={location.legalLand.MER} />
        </>
      )}
      {location.locationType === CoordinateTypes.GPS &&
        location.geometry &&
        location.geometry.lat &&
        location.geometry.long && (
          <>
            <ReviewField
              label="Latitude"
              content={location.geometry.lat.toString()}
            />
            <ReviewField
              label="Longitude"
              content={location.geometry.long.toString()}
            />
          </>
        )}
    </>
  );
};

export default ReviewLocation;
