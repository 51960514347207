import {
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ICoordinates } from '../../Context/DataTypes';
import ElectricAPI from '../../API/GlobalApi';

interface IProps {
  coordinates: ICoordinates;
}

const GoogleMapImage: React.FC<IProps> = (props: IProps) => {
  const { coordinates } = props;
  const theme = useTheme();
  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  const [imageUrl, setImageUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (coordinates.lat || coordinates.long) {
      setLoading(true);
    }

    const delayedCoordinateData = setTimeout(() => {
      if (coordinates && coordinates.lat && coordinates.long) {
        ElectricAPI.getStaticMap(coordinates).then((mapData) => {
          setImageUrl(mapData);
          setLoading(false);
        });
      } else {
        setImageUrl('');
        setLoading(false);
      }
    }, 2000);

    return () => clearTimeout(delayedCoordinateData);
  }, [coordinates]);

  if (loading)
    return (
      <Grid item container justifyContent="center" marginTop="16px">
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );

  return (
    <Grid item container justifyContent="center" marginTop="16px">
      <Grid item borderRadius="10px">
        {imageUrl && loading === false && (
          <img
            src={imageUrl}
            alt="satelite image"
            width={isMobile ? '450px' : '815px'}
            height={isMobile ? '450px' : '516px'}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GoogleMapImage;
