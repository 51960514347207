import {
  Button,
  Dialog,
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  Box,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import React, { useState } from 'react';

const SiteIdDialog: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item paddingTop="0px">
        <Button onClick={handleClickOpen}>
          <Typography variant="smallText">What is a Site ID?</Typography>
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="bodyBold">What is a Site ID?</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <Typography variant="bodyRegular">
                  A site ID is a 13 digit number that is unique to your physical
                  location. Your site ID ties in your physical address, your
                  meter number, the name on your account as well as your contact
                  information. A site ID is a universal number used by ATCO and
                  your retailer when referring to your service​. ATCO site IDs
                  begin with 001.
                </Typography>
              </Box>

              <Box sx={{ paddingTop: '16px' }}>
                <Typography variant="bodyRegular">
                  Your site ID can be found on your power bill, make sure that
                  the address on your power bill is correct. If changes are
                  required contact ATCO or your retailer.
                </Typography>
              </Box>
              <Box sx={{ paddingTop: '16px' }}>
                <Typography variant="bodyRegular">
                  If you do not have your site ID You can contact ATCO at
                  1-800-668-2248 (General Inquiries).
                </Typography>
              </Box>
              <Box sx={{ paddingTop: '16px' }}>
                <Typography variant="bodyRegular">
                  When calling ATCO please have your physical address or lot
                  block plan. If you have a legal land location, there may be
                  multiple site ID's for that section and further information
                  may be required such as a site reference or lot block/plan.
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default SiteIdDialog;
