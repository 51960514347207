/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext } from 'react';
import { DEFAULT_START_DATE } from './Data';
import { ICustomerInformation, IElectricData } from './DataTypes';

interface IElectricAction {
  setData: React.Dispatch<React.SetStateAction<IElectricData>>;
}

const ElectricDataContext = createContext<IElectricData>({} as IElectricData);
const ElectricActionContext = createContext<IElectricAction>({
  setData: React.useState,
} as IElectricAction);
interface IProps {
  children?: React.ReactNode;
}

export const defaultData = {
  acceptedTermsAndConditions: false,
  acceptedNoInvestment: false,
  onFirstNationLand: false,
  acceptedReCaptcha: false,
  requestType: '',
  serviceType: '',
  serviceTypeData: {
    breakerSize: '',
    primaryLineOverOrUnderground: '',
    secondaryVoltage: '',
    secondaryLineOverOrUnderground: '',
    operatingLoad: undefined,
    primaryLineLength: 0,
    serviceEntrance: '',
    serviceEntranceA: '',
    brushing: '',
    brushingValue: 0,
  },
  breakerChange: {
    cabinetAndCableReplace: '',
    existingBreakerSize: '',
    newBreakerSize: '',
  },
  customerInformation: [
    {
      siteID: '',
      companyName: '',
      contactType: 'Legal Land Owner',
      emailAddress: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    } as ICustomerInformation,
  ],
  newLighting: {
    poleType: '',
  },
  perferredStartDate: DEFAULT_START_DATE,
  siteInformation: {
    typeOfLocation: '',
    legalLand: {
      LSD: '',
      Meridian: '',
      Range: '',
      Section: '',
      Township: '',
    },
    civicAddress: {
      streetAddress: '',
      city: '',
      postalCode: '',
      province: '',
      block: '',
      lot: '',
      plan: '',
    },
    gpsCoordinates: {
      lat: '',
      lng: '',
    },
    legalLandCoordinates: [],
  },
  siteInformationImageLoaded: false,
} as any;

const ElectricProvider: React.FC<IProps> = (props: IProps) => {
  const [data, setData] = React.useState<IElectricData>(defaultData);

  return (
    <ElectricActionContext.Provider
      value={{
        setData,
      }}
    >
      <ElectricDataContext.Provider
        value={{
          acceptedTermsAndConditions: data.acceptedTermsAndConditions,
          acceptedNoInvestment: data.acceptedNoInvestment,
          onFirstNationLand: data.onFirstNationLand,
          acceptedReCaptcha: data.acceptedReCaptcha,
          requestType: data.requestType,
          serviceType: data.serviceType,
          serviceTypeData: {
            breakerSize: data.serviceTypeData.breakerSize,
            secondaryVoltage: data.serviceTypeData.secondaryVoltage,
            operatingLoad: data.serviceTypeData.operatingLoad,
            primaryLineOverOrUnderground:
              data.serviceTypeData.primaryLineOverOrUnderground,
            primaryLineLength: data.serviceTypeData.primaryLineLength,
            secondaryLineOverOrUnderground:
              data.serviceTypeData.secondaryLineOverOrUnderground,
            serviceEntrance: data.serviceTypeData.serviceEntrance,
            serviceEntranceA: data.serviceTypeData.serviceEntranceA,
            brushing: data.serviceTypeData.brushing,
            brushingValue: data.serviceTypeData.brushingValue,
          },
          breakerChange: data.breakerChange,
          customerInformation: data.customerInformation,
          newLighting: data.newLighting,
          perferredStartDate: data.perferredStartDate,
          siteInformation: data.siteInformation,
          siteInformationImageLoaded: data.siteInformationImageLoaded,
        }}
      >
        {props.children}
      </ElectricDataContext.Provider>
    </ElectricActionContext.Provider>
  );
};

export {
  ElectricDataContext as electricData,
  ElectricActionContext as electricActions,
};

export default ElectricProvider;
