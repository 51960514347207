import {
  Checkbox,
  createStyles,
  Link,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useState } from 'react';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import DatePicker from '@material-ui/lab/DatePicker';
import Warning from '../Components/Warning';
import { DEFAULT_START_DATE } from '../Context/Data';
import PreviousNextButtons from '../Components/common/PreviousNextButtons';
import { UploadStatus } from '../Context/Enums';
import SnackbarAlert from '../Components/common/SnackbarAlert';
import UserDataContext from '../Context/UserDataContext';
import useFirebaseStorage from '../Hooks/useFirebaseStorage';
import moment from 'moment';
import { getLatestDate } from '../Components/helpers/util';
import { UserDataActionType } from '../Context/UserDataStateReducer';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      border: '1px solid #DDDDDD',
      borderRadius: '4px',
      minHeight: 0,
      paddingBottom: theme.spacing(3),
    },
    root: {
      '& .MuiCalenderPicker-root': {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '21.79px',
      },
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
  })
);

interface IProps {
  requestType: string;
}

const UploadDocuments: React.FC<IProps> = (props: IProps) => {
  const { requestType } = props;
  const classes = useStyles();
  const theme = useTheme();
  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  const history = useHistory();
  const { state: userState, dispatch } = useContext(UserDataContext);
  const { uploadDocuments, removeDocuments } = useFirebaseStorage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileSizeRejected, setFileSizeRejected] = useState<boolean>(false);
  const [additionalInfo, setAdditionalInfo] = useState<string>(
    userState.userData.additionalInfo
  );
  const [uploadAlert, setUploadAlert] = useState<string>('');
  const [files, setFiles] = useState<FileObject[]>([]);
  const [hasNoDocuments, setHasNoDocuments] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(
    getLatestDate(
      userState.userData.requestInfo.preferredStartDate,
      DEFAULT_START_DATE
    )
  );

  const onAddFiles = (newFiles: FileObject[]) => {
    setIsLoading(true);
    const found = files.find(
      (file) => newFiles[0].file.name === file.file.name
    );

    if (!found) {
      setFiles([...files, ...newFiles]);
      newFiles.forEach((file) => {
        uploadDocuments(file, userState.userData.uniqueId).then((result) => {
          if (result) {
            setUploadAlert(UploadStatus.SUCCESS_UPLOAD);
            setIsLoading(false);
          } else {
            setUploadAlert(UploadStatus.FAILURE_UPLOAD);
            setIsLoading(false);
          }
        });
      });
    }
  };

  const onRemoveFile = async (deleteFileObj: FileObject) => {
    setIsLoading(true);
    const found = files.find(
      (file) => deleteFileObj.file.name === file.file.name
    );

    if (found) {
      const result = await removeDocuments(
        deleteFileObj,
        userState.userData.uniqueId
      );
      if (result) {
        const filtered = files.filter(
          (file) => deleteFileObj.file.name !== file.file.name
        );

        setFiles(filtered);
        setUploadAlert(UploadStatus.SUCCESS_REMOVAL);
        setIsLoading(false);
      } else {
        setUploadAlert(UploadStatus.FAILURE_REMOVAL);
        setIsLoading(false);
      }
    }
  };

  const onFileTooLarge = (
    rejectedFile: any,
    aceptedFiles: string[],
    maxFileSize: number
  ) => {
    if (rejectedFile.size > maxFileSize) {
      setFileSizeRejected(true);
    }
    return '';
  };

  const handleNextClick = () => {
    dispatch({
      type: UserDataActionType.DocumentRequestData,
      payload: {
        path: `userDoc/${userState.userData.uniqueId}`,
        startDate: startDate,
        additionalInfo: additionalInfo,
      },
    });

    history.push(`/request-type/${requestType}/land-owner-info`);
  };
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{ marginLeft: isMobile ? '10px' : '0px' }}
    >
      <Grid item>
        <Typography variant="h1">Site Documents</Typography>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="bodyBold">
            Please upload your site plan, single line diagram (SLD) and pictures
            of site (including transformer location). If possible, include power
            pole numbers on site plan and/or in notes.
          </Typography>
        </Grid>
        <Grid item>
          <DropzoneAreaBase
            dropzoneClass={classes.dropzone}
            fileObjects={files}
            maxFileSize={10000000}
            filesLimit={10}
            getDropRejectMessage={onFileTooLarge}
            onAdd={onAddFiles}
            onDelete={onRemoveFile}
            dropzoneText="Upload Document"
            showAlerts={false}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          />
        </Grid>
      </Grid>
      <Grid item style={{ paddingTop: '0px' }}>
        <Typography variant="smallText">
          Hand sketch and/or pictures are required for residential and farm
          customers. Site plan, SLD (Single Line Diagram) are required for
          Commercial/Industrial customers.
        </Typography>
      </Grid>
      <Grid item>
        <Link variant="addEditLink" href="/PDFs/Example1.pdf" target="_blank">
          Site Plan Example
        </Link>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        padding="0"
        wrap="nowrap"
      >
        <Grid item>
          <Checkbox
            color="primary"
            disabled={files.length > 0}
            value={hasNoDocuments}
            onChange={(e) => {
              setHasNoDocuments(e.target.checked);
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            variant={files.length ? 'disabledRegular' : 'bodyRegular'}
          >
            I do not have these documents right now.
          </Typography>
        </Grid>
      </Grid>
      {hasNoDocuments && (
        <Grid item marginTop="16px">
          <Warning basic={true} />
        </Grid>
      )}
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="bodyBold">
            When would you like your services to start?
          </Typography>
        </Grid>
        <Grid item container width="100%">
          <DatePicker
            className={classes.root}
            minDate={DEFAULT_START_DATE}
            value={startDate}
            PopperProps={{
              style: {
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '21.79px',
              },
            }}
            onChange={(date) =>
              setStartDate(moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY'))
            }
            inputFormat={'MM/DD/YYYY'}
            renderInput={(params) => (
              <TextField
                style={{
                  width: '100%',
                }}
                {...params}
                helperText={false}
              />
            )}
          />
        </Grid>

        <Grid item style={{ paddingTop: '0px' }}>
          <Typography variant="smallText">
            Please note, it takes approximately 60 days until you have power
            from the date the contract is signed by you and us. The in-service
            date is dependent on weather, terrain, and project specific
            requirements.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid>
          <Typography variant="bodyBold">
            Anything else we should know about the site ?
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            multiline
            rows={4}
            placeholder="What is this service for? Will the new powerline cross a road way? Will you need a light or any other services?"
            fullWidth
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />
        </Grid>
      </Grid>
      <PreviousNextButtons
        nextDisabled={
          (hasNoDocuments === false && files.length === 0) || isLoading
        }
        clickNextHandler={handleNextClick}
      />
      <SnackbarAlert
        open={uploadAlert === UploadStatus.SUCCESS_REMOVAL}
        content="Successfully removed document(s)"
        closeMessage={() => setUploadAlert(UploadStatus.CLEAR)}
        error={false}
      />
      <SnackbarAlert
        open={uploadAlert === UploadStatus.SUCCESS_UPLOAD}
        content="Successfully attached document(s)"
        closeMessage={() => setUploadAlert(UploadStatus.CLEAR)}
        error={false}
      />
      <SnackbarAlert
        open={uploadAlert === UploadStatus.FAILURE_UPLOAD}
        content="Something went wrong with attached document(s)!"
        closeMessage={() => setUploadAlert(UploadStatus.CLEAR)}
        error={true}
      />
      <SnackbarAlert
        open={uploadAlert === UploadStatus.FAILURE_REMOVAL}
        content="Something went wrong when removing document(s)!"
        closeMessage={() => setUploadAlert(UploadStatus.CLEAR)}
        error={true}
      />
      <SnackbarAlert
        open={fileSizeRejected}
        content="File size is too large, limit is 10MB"
        closeMessage={() => setFileSizeRejected(false)}
        error={true}
      />
    </Grid>
  );
};

export default UploadDocuments;
