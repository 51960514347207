import React, { useReducer } from 'react';
import userDataStateReducer from './UserDataStateReducer';
import UserDataContext, { initialState } from './UserDataContext';

interface Props {
  children?: React.ReactNode;
}

const UserDataContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(userDataStateReducer, initialState);

  return (
    <UserDataContext.Provider value={{ state, dispatch }}>
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataContextProvider;
