import React from 'react';
import {
  Box,
  Button,
  CssBaseline,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';
import Attributions from '../Pages/Attributions';

interface IProps {
  children?: React.ReactNode;
}

const Layout: React.FC<IProps> = (props: IProps) => {
  const location = useLocation();
  const path = location.pathname;
  const theme = useTheme();
  const lowestScreenSize = 1200;
  const matches = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <CssBaseline />
      <Navbar />
      <Box
        display="block"
        marginTop={matches ? '12px' : '88px'}
        flexDirection="column"
        justifyContent="flex-start"
        height="100%"
      >
        {props.children}
      </Box>
      {path.includes('review') && !matches && <Box marginTop="auto"></Box>}
      <Box marginTop="auto">
        {' '}
        {!matches && (
          <Box>
            <Box height="35px"></Box>
            <Box
              id="INLAYOUT"
              width="100%"
              marginTop={path.includes('required-info') ? '32px' : 'auto'}
              height="84px"
              alignSelf="flex-end"
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <Box
                sx={{
                  display: 'flex',
                  paddingTop: '32px',
                  justifyContent: 'space-between',
                  paddingRight: '80px',
                  paddingLeft: '80px',
                }}
              >
                <Box>
                  <Button onClick={handleClickOpen}>
                    <Typography
                      noWrap={true}
                      sx={{
                        fontFamily: 'open sans',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16.41px',
                        color: 'white',
                        textDecoration: 'underline',
                      }}
                    >
                      ATTRIBUTIONS
                    </Typography>
                  </Button>
                  <Attributions open={open} handleClose={handleClose} />
                </Box>
                <Box>
                  <Typography
                    noWrap={true}
                    sx={{
                      fontFamily: 'open sans',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '16.41px',
                      color: 'white',
                    }}
                  >
                    COPYRIGHT 2023 ATCO LTD. ALL RIGHTS RESERVED.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Layout;
