import moment from 'moment';
import SiteContent from '../SiteContent/SiteContent';
import {
  ContactTypes,
  CoordinateTypes,
  NewConnectionServiceType,
  ServiceRequestType,
} from './Enums';
import { IDropdownOption, INewServiceTypeOption } from './DataTypes';

export const requestTypeData = [
  {
    key: ServiceRequestType.NEW_CONNECTION,
    title: 'New Connection',
    description: SiteContent.requestType.newConntection,
  },
  {
    key: ServiceRequestType.BREAKER_CHANGE,
    title: 'Breaker Change',
    description: SiteContent.requestType.breakerText,
  },
  {
    key: ServiceRequestType.NEW_LIGHTING,
    title: 'New Lighting',
    description: SiteContent.requestType.newLighting,
  },
];

export const breakerSize = [
  {
    key: '35amp',
    title: '35 amp',
    description: SiteContent.requiredType.breakOptions.breaker35,
  },

  {
    key: '50amp',
    title: '50 amp',
    description: SiteContent.requiredType.breakOptions.breaker50,
  },
  {
    key: '80amp',
    title: '80 amp',
    description: SiteContent.requiredType.breakOptions.breaker80,
  },
  {
    key: '100amp',
    title: '100 amp',
    description: SiteContent.requiredType.breakOptions.breaker100,
  },
  {
    key: '200amp',
    title: '200A',
    description: '200A',
  },
];

export const voltage = [
  {
    key: '120-240V',
    title: '120/240 Volt, 1 Phase',
    description: SiteContent.requiredType.voltageOptions.phase1Wire3Text,
    flow: [
      'residential',
      'farm',
      'small-general-service',
      'small-oilfield-pumping-power',
    ],
  },
  {
    key: '120-208V',
    title: '120/208 Volt, 3 Phase, WYE',
    description: SiteContent.requiredType.voltageOptions.phase3Wire4Text,
    flow: ['farm', 'small-general-service', 'small-oilfield-pumping-power'],
  },
  {
    key: '277-480V',
    title: '277/480 Volt, 3 Phase, WYE',
    description: SiteContent.requiredType.voltageOptions.phase3Wire4VoltText,
    flow: ['farm', 'small-general-service', 'small-oilfield-pumping-power'],
  },
  {
    key: '347-600V',
    title: '347/600 Volt, 3 Phase, WYE',
    description: SiteContent.requiredType.voltageOptions.phase3Wire3Volt600Text,
    flow: ['farm', 'small-general-service', 'small-oilfield-pumping-power'],
  },
];

export const lines: IDropdownOption[] = [
  {
    key: 'overhead',
    title: 'Overhead – Polemount Transformer',
    description: SiteContent.requiredType.lineOptions.overheadText,
    disabled: true,
  },
  {
    key: 'underground',
    title: 'Underground – Padmount Transformer',
    description: SiteContent.requiredType.lineOptions.undergroundText,
    disabled: false,
  },
];

export const secondaryLines: IDropdownOption[] = [
  {
    key: 'overhead',
    title: 'Overhead',
    description: 'Overhead – ATCO provided',
  },
  {
    key: 'underground',
    title: 'Underground',
    description: 'Underground – Customer provided',
  },
];

export const residentialServiceEntrances: IDropdownOption[] = [
  { key: '100A', title: '100A', description: '100A' },
  { key: '200A', title: '200A', description: '200A' },
  { key: '0', title: 'I dont know', description: 'I dont know' },
];

export const serviceEntrances: IDropdownOption[] = [
  {
    key: '100',
    title: '100',
    description: '100',
  },
  {
    key: '200',
    title: '200',
    description: '200',
  },
  {
    key: '400',
    title: '400',
    description: '400',
  },
  {
    key: '600',
    title: '600',
    description: '600',
  },
];

export const typeOfNewService: INewServiceTypeOption[] = [
  {
    key: NewConnectionServiceType.RESIDENTIAL,
    title: 'Residential (D11)',
    description: SiteContent.requiredType.residentialText,
    hyperLinkText: '',
  },
  {
    key: NewConnectionServiceType.FARM,
    title: 'Farm (D56)',
    description: SiteContent.requiredType.farmText,
    hyperLinkText: 'Do I qualify for the farm rate?',
  },
  {
    key: NewConnectionServiceType.SMALL_GENERAL,
    title: 'Small General Service (D21)',
    description: SiteContent.requiredType.generalServiceText,
    hyperLinkText: '',
  },
  {
    key: NewConnectionServiceType.SMALL_OILFIELD,
    title: 'Small Oilfield & Pumping Power (D41)',
    description: SiteContent.requiredType.oilfield,
    hyperLinkText: '',
  },
];

export const typeOfNewLighting = [
  {
    key: 'existing-pole',
    title: 'Existing Pole',
    description: SiteContent.requiredType.woodOptions.existinPoleText,
  },
  {
    key: 'new-pole',
    title: 'New Wood Pole',
    description: 'New Wood Pole Is Required',
  },
];

export const vegetationRemoval: IDropdownOption[] = [
  { key: 'yes', title: 'Yes', description: 'Yes' },
  { key: 'no', title: 'No', description: 'No' },
];

export const requiredInfoBreakerSize = [
  {
    title: '30A',
    description: SiteContent.requiredType.breakOptions.breaker30,
  },

  {
    title: '35A',
    description: SiteContent.requiredType.breakOptions.breaker35,
  },
  {
    title: '50A',
    description: SiteContent.requiredType.breakOptions.breaker50,
  },
  {
    title: '80A',
    description: SiteContent.requiredType.breakOptions.breaker80,
  },
  {
    title: '100A',
    description: SiteContent.requiredType.breakOptions.breaker100,
  },
  {
    title: '200A',
    description: SiteContent.requiredType.breakOptions.breaker200,
  },
  {
    title: 'I dont know',
    description: SiteContent.requiredType.idk,
  },
];

export const locationTypes = [
  {
    key: 'legal-land',
    title: CoordinateTypes.LEGAL,
    description: CoordinateTypes.LEGAL,
  },
  {
    key: 'gps',
    title: CoordinateTypes.GPS,
    description: CoordinateTypes.GPS,
  },
];

export const provinces = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon Territory',
];

export const stepperData = [
  {
    step: 1,
    title: 'Welcome',
  },
  {
    step: 2,
    title: 'Request Type',
  },
  {
    step: 3,
    title: 'Required Info',
  },
  {
    step: 4,
    title: 'Site Information',
  },
  {
    step: 5,
    title: 'Upload Documents',
  },
  {
    step: 6,
    title: 'Land Owner Information',
  },
  {
    step: 7,
    title: 'Review & Submit',
  },
];

export const customerTypeOptions = [
  {
    key: ContactTypes.PRIMARY_SITE_CONTACT,
    title: 'Primary Site Contact',
    description: 'Primary Site Contact',
    disabled: false,
  },
  {
    key: ContactTypes.SECONDARY_SITE_CONTACT,
    title: 'Secondary Site Contact',
    description: 'Secondary Site Contact',
    disabled: false,
  },
  {
    key: ContactTypes.ELECTRICIAN_CONSULTANT,
    title: 'Electrician/Consultant',
    description: 'Electrician / Consultant',
    disabled: false,
  },
  {
    key: ContactTypes.ATCO_EXPEDITOR,
    title: 'ATCO Expeditor',
    description: 'ATCO Expeditor',
    disabled: false,
  },
];

export const QSData = [
  { value: 'NW', title: 'NW' },
  { value: 'NE', title: 'NE' },
  { value: 'SW', title: 'SW' },
  { value: 'SE', title: 'SE' },
];

export const LSDData = [
  { value: 'NW', title: 'NW' },
  { value: 'NE', title: 'NE' },
  { value: 'SW', title: 'SW' },
  { value: 'SE', title: 'SE' },
  { value: 'N', title: 'N' },
  { value: 'S', title: 'S' },
  { value: 'E', title: 'E' },
  { value: 'W', title: 'W' },
  { value: '01', title: 'LSD 1' },
  { value: '02', title: 'LSD 2' },
  { value: '03', title: 'LSD 3' },
  { value: '04', title: 'LSD 4' },
  { value: '05', title: 'LSD 5' },
  { value: '06', title: 'LSD 6' },
  { value: '07', title: 'LSD 7' },
  { value: '08', title: 'LSD 8' },
  { value: '09', title: 'LSD 9' },
  { value: '10', title: 'LSD 10' },
  { value: '11', title: 'LSD 11' },
  { value: '12', title: 'LSD 12' },
  { value: '13', title: 'LSD 13' },
  { value: '14', title: 'LSD 14' },
  { value: '15', title: 'LSD 15' },
  { value: '16', title: 'LSD 16' },
];

export const meridianData = [
  {
    title: 'W4',
    value: '4',
  },
  {
    title: 'W5',
    value: '5',
  },
  {
    title: 'W6',
    value: '6',
  },
];

export const sectionOptions = [
  { value: '01', title: '1' },
  { value: '02', title: '2' },
  { value: '03', title: '3' },
  { value: '04', title: '4' },
  { value: '05', title: '5' },
  { value: '06', title: '6' },
  { value: '6A', title: '6A' },
  { value: '07', title: '7' },
  { value: '7A', title: '7A' },
  { value: '08', title: '8' },
  { value: '09', title: '9' },
  { value: '10', title: '10' },
  { value: '11', title: '11' },
  { value: '12', title: '12' },
  { value: '13', title: '13' },
  { value: '14', title: '14' },
  { value: '15', title: '15' },
  { value: '16', title: '16' },
  { value: '17', title: '17' },
  { value: '18', title: '18' },
  { value: '18A', title: '18A' },
  { value: '19', title: '19' },
  { value: '19A', title: '19A' },
  { value: '20', title: '20' },
  { value: '21', title: '21' },
  { value: '22', title: '22' },
  { value: '23', title: '23' },
  { value: '24', title: '24' },
  { value: '25', title: '25' },
  { value: '26', title: '26' },
  { value: '27', title: '27' },
  { value: '28', title: '28' },
  { value: '29', title: '29' },
  { value: '30', title: '30' },
  { value: '30A', title: '30A' },
  { value: '31', title: '31' },
  { value: '31A', title: '31A' },
  { value: '32', title: '32' },
  { value: '33', title: '33' },
  { value: '34', title: '34' },
  { value: '35', title: '35' },
  { value: '36', title: '36' },
  { value: '36A', title: '36A' },
];

export const townshipOptions = [
  { value: '01', title: '1' },
  { value: '02', title: '2' },
  { value: '03', title: '3' },
  { value: '04', title: '4' },
  { value: '05', title: '5' },
  { value: '06', title: '6' },
  { value: '07', title: '7' },
  { value: '08', title: '8' },
  { value: '09', title: '9' },
  { value: '010', title: '10' },
  { value: '011', title: '11' },
  { value: '012', title: '12' },
  { value: '013', title: '13' },
  { value: '014', title: '14' },
  { value: '015', title: '15' },
  { value: '016', title: '16' },
  { value: '017', title: '17' },
  { value: '018', title: '18' },
  { value: '019', title: '19' },
  { value: '19A', title: '19A' },
  { value: '020', title: '20' },
  { value: '021', title: '21' },
  { value: '022', title: '22' },
  { value: '023', title: '23' },
  { value: '024', title: '24' },
  { value: '025', title: '25' },
  { value: '026', title: '26' },
  { value: '027', title: '27' },
  { value: '27A', title: '27A' },
  { value: '028', title: '28' },
  { value: '029', title: '29' },
  { value: '030', title: '30' },
  { value: '031', title: '31' },
  { value: '032', title: '32' },
  { value: '033', title: '33' },
  { value: '034', title: '34' },
  { value: '035', title: '35' },
  { value: '036', title: '36' },
  { value: '037', title: '37' },
  { value: '038', title: '38' },
  { value: '039', title: '39' },
  { value: '040', title: '40' },
  { value: '041', title: '41' },
  { value: '042', title: '42' },
  { value: '42A', title: '42A' },
  { value: '043', title: '43' },
  { value: '43A', title: '43A' },
  { value: '044', title: '44' },
  { value: '045', title: '45' },
  { value: '45A', title: '45A' },
  { value: '046', title: '46' },
  { value: '46A', title: '46A' },
  { value: '047', title: '47' },
  { value: '47A', title: '47A' },
  { value: '048', title: '48' },
  { value: '48A', title: '48A' },
  { value: '49', title: '49' },
  { value: '050', title: '50' },
  { value: '051', title: '51' },
  { value: '052', title: '52' },
  { value: '053', title: '53' },
  { value: '054', title: '54' },
  { value: '055', title: '55' },
  { value: '056', title: '56' },
  { value: '057', title: '57' },
  { value: '058', title: '58' },
  { value: '059', title: '59' },
  { value: '060', title: '60' },
  { value: '061', title: '61' },
  { value: '062', title: '62' },
  { value: '063', title: '63' },
  { value: '064', title: '64' },
  { value: '065', title: '65' },
  { value: '066', title: '66' },
  { value: '067', title: '67' },
  { value: '068', title: '68' },
  { value: '069', title: '69' },
  { value: '070', title: '70' },
  { value: '071', title: '71' },
  { value: '072', title: '72' },
  { value: '073', title: '73' },
  { value: '074', title: '74' },
  { value: '075', title: '75' },
  { value: '076', title: '76' },
  { value: '077', title: '77' },
  { value: '078', title: '78' },
  { value: '079', title: '79' },
  { value: '080', title: '80' },
  { value: '081', title: '81' },
  { value: '082', title: '82' },
  { value: '083', title: '83' },
  { value: '084', title: '84' },
  { value: '085', title: '85' },
  { value: '086', title: '86' },
  { value: '087', title: '87' },
  { value: '088', title: '88' },
  { value: '089', title: '89' },
  { value: '090', title: '90' },
  { value: '091', title: '91' },
  { value: '092', title: '92' },
  { value: '093', title: '93' },
  { value: '094', title: '94' },
  { value: '095', title: '95' },
  { value: '096', title: '96' },
  { value: '097', title: '97' },
  { value: '098', title: '98' },
  { value: '099', title: '99' },
  { value: '100', title: '100' },
  { value: '101', title: '101' },
  { value: '102', title: '102' },
  { value: '103', title: '103' },
  { value: '104', title: '104' },
  { value: '105', title: '105' },
  { value: '106', title: '106' },
  { value: '107', title: '107' },
  { value: '108', title: '108' },
  { value: '109', title: '109' },
  { value: '110', title: '110' },
  { value: '111', title: '111' },
  { value: '112', title: '112' },
  { value: '113', title: '113' },
  { value: '114', title: '114' },
  { value: '115', title: '115' },
  { value: '116', title: '116' },
  { value: '117', title: '117' },
  { value: '118', title: '118' },
  { value: '119', title: '119' },
  { value: '120', title: '120' },
  { value: '121', title: '121' },
  { value: '122', title: '122' },
  { value: '123', title: '123' },
  { value: '124', title: '124' },
  { value: '125', title: '125' },
  { value: '126', title: '126' },
  { value: '127', title: '127' },
];

export const rangeData = [
  { value: '01', title: '1' },
  { value: '02', title: '2' },
  { value: '03', title: '3' },
  { value: '04', title: '4' },
  { value: '05', title: '5' },
  { value: '06', title: '6' },
  { value: '07', title: '7' },
  { value: '08', title: '8' },
  { value: '09', title: '9' },
  { value: '10', title: '10' },
  { value: '11', title: '11' },
  { value: '12', title: '12' },
  { value: '13', title: '13' },
  { value: '13A', title: '13A' },
  { value: '14', title: '14' },
  { value: '15', title: '15' },
  { value: '16', title: '16' },
  { value: '17', title: '17' },
  { value: '18', title: '18' },
  { value: '19', title: '19' },
  { value: '20', title: '20' },
  { value: '21', title: '21' },
  { value: '21A', title: '21A' },
  { value: '22', title: '22' },
  { value: '23', title: '23' },
  { value: '24', title: '24' },
  { value: '24A', title: '24A' },
  { value: '25', title: '25' },
  { value: '26', title: '26' },
  { value: '26A', title: '26A' },
  { value: '27', title: '27' },
  { value: '28', title: '28' },
  { value: '29', title: '29' },
  { value: '29A', title: '29A' },
  { value: '30', title: '30' },
  { value: '31', title: '31' },
  { value: '32', title: '32' },
  { value: '33', title: '33' },
  { value: '34', title: '34' },
];

export const DEFAULT_START_DATE = moment().add(60, 'days').format('MM/DD/YYYY');
