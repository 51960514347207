import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import SelectionCard from '../Components/SelectionCard';
import { typeOfNewService } from '../Context/Data';
import HelpLink from '../Components/common/HelpLink';

interface IProps {
  newServiceType: string;
  setNewServiceType: React.Dispatch<React.SetStateAction<string>>;
}

const NewConnectionRequiredInfo: React.FC<IProps> = (props: IProps) => {
  const { newServiceType, setNewServiceType } = props;

  return (
    <>
      <Grid item>
        <Grid container direction="column" spacing={2}>
          {typeOfNewService.map((service) => (
            <Grid
              item
              container
              key={service.key}
              onClick={() => {
                setNewServiceType(service.key);
              }}
            >
              <SelectionCard
                selected={newServiceType === service.key}
                title={service.title}
                description={service.description}
                hyperLinkText={service.hyperLinkText}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item container marginTop="16px" alignItems="center">
        <HelpLink
          label=" Price Schedule Form"
          href="https://www.atco.com/content/dam/web/for-home/electricity/egbu-2020-08-01-atco-price-schedules-q4.pdf"
        />
      </Grid>
    </>
  );
};

export default NewConnectionRequiredInfo;
