import {
  customerTypeOptions,
  DEFAULT_START_DATE,
  requestTypeData,
  typeOfNewLighting,
  typeOfNewService,
} from '../../Context/Data';
import { ICustomerInformation, ISubmissionData } from '../../Context/DataTypes';
import moment from 'moment';
import { ContactTypes } from '../../Context/Enums';

export const getLatestDate = (
  firstDate: string,
  secondDate: string
): string => {
  return new Date(firstDate) > new Date(secondDate) ? firstDate : secondDate;
};

export const validateSitId = (siteId: string | undefined) => {
  if (siteId) {
    if (siteId.length === 0) return false;
    if (siteId.length < 10 || siteId.length > 10) return true;
  }
  return false;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length === 0) return false;
  return !/^\s?\d{3}?[\s-]?\d{3}[\s-]?\d{4}$/.test(phoneNumber);
};

export const validateEmail = (email: string) => {
  if (email.length === 0) return false;
  return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validatePostalCode = (postalCode: string) => {
  if (postalCode.length === 0) return false;
  return !/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
    postalCode
  );
};

export const mapRequestIdToName = (requestId: string): string => {
  return requestTypeData.find((req) => req.key === requestId)?.title || '';
};

export const mapServiceTypeIdToName = (type: string): string => {
  return typeOfNewService.find((req) => req.key === type)?.title || '';
};

export const mapLandOwner = (type: string): string => {
  if (type === ContactTypes.LEGAL_LAND_OWNER) return 'Legal Land Owner';
  else {
    return customerTypeOptions.find((req) => req.key === type)?.title || '';
  }
};

export const mapPoleTypeToName = (type: string): string => {
  return typeOfNewLighting.find((light) => light.key === type)?.title || '';
};
export const mapElectricDataToSubmit = (data: ISubmissionData): any => {
  return {
    uniqueId: data.uniqueId,
    requestInfo: {
      requestType: mapRequestIdToName(data.requestInfo.requestType),
      lessThanOneYearService: data.requestInfo.lessThanOneYearService,
      onFirstNationLand: data.requestInfo.onFirstNationLand,
      submissionDateAndTime: new Date().toLocaleDateString(),
      preferredStartDate: data.requestInfo.preferredStartDate,
      serviceType: mapServiceTypeIdToName(data.requestInfo.serviceType),
      newLighting: {
        poleType: mapPoleTypeToName(data.requestInfo.newLighting.poleType),
      },
    },
    customerInfo: data.customerInfo.map((customer: ICustomerInformation) => {
      return {
        siteID: customer.siteID ? `001${customer.siteID}` : '',
        contactType: mapLandOwner(customer.contactType),
        fullName:
          customer.contactType === ContactTypes.ATCO_EXPEDITOR
            ? `${customer.firstName} ${customer.lastName}`
            : '',
        firstName: customer.firstName,
        lastName: customer.lastName,
        companyName: customer.companyName,
        phoneNumber: customer.phoneNumber,
        emailAddress: customer.emailAddress,
        streetAddress: customer.streetAddress,
        city: customer.city,
        postalCode: customer.postalCode,
      };
    }),
    electricInfo: {
      primaryLineOverheadOrUnderground:
        data.electricInfo.primaryLineOverOrUnderground,
      secondaryLineOverheadOrUnderground:
        data.electricInfo.secondaryLineOverOrUnderground,
      lengthOfPrimaryLine: data.electricInfo.primaryLineLength,
      secondaryVoltage: data.electricInfo.secondaryVoltage,
      serviceEntrance: data.electricInfo.serviceEntrance,
      brushing: data.electricInfo.brushing,
      brushingValue: data.electricInfo.brushingValue,
      serviceEntranceA: data.electricInfo.serviceEntranceA,
      breakerSize: data.electricInfo.breakerSize,
      operatingLoad: data.electricInfo.operatingLoad || 0,
      poleType: mapPoleTypeToName(data.requestInfo.newLighting.poleType),
    },
    locationInfo: {
      locationType: data.locationInfo.locationType,
      LSD: data.locationInfo.legalLand?.LSD,
      section: data.locationInfo.legalLand?.SEC,
      township: data.locationInfo.legalLand?.TWP,
      range: data.locationInfo.legalLand?.RGE,
      meridian: data.locationInfo.legalLand?.MER,
      geometry: [
        {
          lat:
            data.locationInfo.geometry && data.locationInfo.geometry.lat
              ? data.locationInfo.geometry.lat
              : undefined,
          lng:
            data.locationInfo.geometry && data.locationInfo.geometry.long
              ? data.locationInfo.geometry.long
              : undefined,
        },
      ],
    },
    attachmentPath: `userDoc/${data.uniqueId}`,
    additionalInfo: data.additionalInfo,
  };
};

export function parsePreferredStartDate(startDate: string): boolean {
  return moment(startDate, 'MM/DD/YYYY', true).isValid();
}

export function checkIfDateIsLessThan60Days(startDate: string): boolean {
  return moment(startDate, 'MM/DD/YYYY').isSameOrAfter(
    moment(DEFAULT_START_DATE, 'MM/DD/YYYY')
  );
}

export function isLegalLandOwnerFormFilled(
  customer: ICustomerInformation
): boolean {
  if (
    customer.firstName &&
    customer.lastName &&
    customer.emailAddress &&
    customer.phoneNumber &&
    customer.streetAddress &&
    customer.city &&
    customer.postalCode
  ) {
    return true;
  } else {
    return false;
  }
}

export function isLegalLandOwnerBreakerChangeFormFilled(
  customer: ICustomerInformation
): boolean {
  if (
    customer.firstName !== '' &&
    customer.lastName !== '' &&
    customer.emailAddress !== '' &&
    customer.phoneNumber !== '' &&
    customer.streetAddress !== '' &&
    customer.city !== '' &&
    customer.postalCode !== '' &&
    customer.siteID !== ''
  ) {
    return true;
  } else {
    return false;
  }
}

export function isATCOExpeditorFormFilled(
  customer: ICustomerInformation
): boolean {
  if (customer.firstName && customer.lastName) {
    return true;
  } else {
    return false;
  }
}

export function isOtherContactFormFilled(
  customer: ICustomerInformation
): boolean {
  if (customer.firstName && customer.lastName && customer.phoneNumber) {
    return true;
  } else {
    return false;
  }
}

export const isLatitude = (num: number): boolean =>
  isFinite(num) && Math.abs(num) <= 90;
export const isLongitude = (num: number): boolean =>
  isFinite(num) && Math.abs(num) <= 180;
