export enum ServiceRequestType {
  NEW_CONNECTION = 'new-connection',
  BREAKER_CHANGE = 'breaker-change',
  NEW_LIGHTING = 'new-lighting',
}

export enum NewConnectionServiceType {
  RESIDENTIAL = 'residential',
  FARM = 'farm',
  SMALL_GENERAL = 'small-general-service',
  SMALL_OILFIELD = 'small-oilfield-pumping-power',
}

export enum PowerLineType {
  UNDERGROUND = 'Underground',
  OVERHEAD = 'Overhead',
}

export enum Steps {
  welcome = 'Welcome',
  requestType = 'Request Type',
  requiredInfo = 'Required Info',
  siteInformation = 'Site Information',
  uploadDocuments = 'Upload Documents',
  landOwner = 'Land Owner Information',
  review = 'Review & Submit',
}

export enum CoordinateTypes {
  GPS = 'GPS Coordinates',
  LEGAL = 'Legal Land Location',
}

export enum UploadStatus {
  FAILURE_UPLOAD = 'failure-upload',
  SUCCESS_UPLOAD = 'success-upload',
  FAILURE_REMOVAL = 'failure-removal',
  SUCCESS_REMOVAL = 'success-removal',
  CLEAR = '',
}

export enum ContactTypes {
  LEGAL_LAND_OWNER = 'legal-land-owner',
  PRIMARY_SITE_CONTACT = 'primary-site-contact',
  SECONDARY_SITE_CONTACT = 'secondary-site-contact',
  ELECTRICIAN_CONSULTANT = 'electrician-consultant',
  ATCO_EXPEDITOR = 'atco-expeditor',
}
