import { ICustomerInformation } from '../../Context/DataTypes';
import { ContactTypes, ServiceRequestType } from '../../Context/Enums';
import LegalLandOwnerInfo from './LegalLandOwnerInfo';
import ATCOExpeditorContactInfo from './ATCOExpeditorContactInfo';
import OtherContactInfo from './OtherContactInfo';

interface IProps {
  requestType: string;
  customerInfo: ICustomerInformation;
}

const ReviewContactInfo: React.FC<IProps> = (props: IProps) => {
  const { requestType, customerInfo } = props;

  return (
    <>
      {customerInfo.contactType === ContactTypes.LEGAL_LAND_OWNER ? (
        <LegalLandOwnerInfo
          customerInfo={customerInfo}
          showSiteId={requestType === ServiceRequestType.BREAKER_CHANGE}
        />
      ) : customerInfo.contactType === ContactTypes.ATCO_EXPEDITOR ? (
        <ATCOExpeditorContactInfo customerInfo={customerInfo} />
      ) : (
        <OtherContactInfo customerInfo={customerInfo} />
      )}
    </>
  );
};

export default ReviewContactInfo;
