import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Button,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import FeedbackForm from './FeedbackForm';
import Attributions from '../Pages/Attributions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: theme.spacing(2),
      justifyContent: 'space-between',
      width: '100%',
    },
    logoImageContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      alignItems: 'flex-start',
      display: 'inline-flex',
    },
    logoImage: {
      height: theme.spacing(5),
      alignSelf: 'flex-start',
      objectFit: 'contain',
      marginBottom: theme.spacing(1),
    },
    titleAndNumberContainer: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'flex',
      paddingBottom: theme.spacing(1),
    },
    dividerContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      height: '40%',
    },
    menuItems: {
      display: 'flex',
      flexDirection: 'column',
      height: '80px',
      width: '100%',
      backgroundColor: '#0057BB',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'inset 0px -1px 0px #FFFFFF',
    },
  })
);

const NavBar: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const lowestScreenSize = 1200;
  const matches = useMediaQuery(theme.breakpoints.down(lowestScreenSize));

  const [openFeedbackMenu, setOpenFeedbackMenu] = React.useState<boolean>(
    false
  );
  const [openFeedback, setOpenFeedback] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      zIndex="1000"
      marginBottom="88px"
    >
      <AppBar
        elevation={0}
        style={{ maxHeight: '96px', width: '100%', position: 'fixed' }}
      >
        <Toolbar className={classes.root}>
          <Box
            className={classes.logoImageContainer}
            style={{ paddingLeft: matches ? '16px' : '88px' }}
          >
            <img
              className={classes.logoImage}
              src="/images/VersionWhiteLogo@1x.png"
              title="ATCO logo"
            />
          </Box>
          <Box
            className={classes.titleAndNumberContainer}
            style={{
              marginLeft: 'auto',
              paddingRight: matches ? '24px' : '72px',
            }}
          >
            {!matches && (
              <Typography noWrap={true} variant="h2">
                QUESTIONS? CALL US 1-800-668-2248
              </Typography>
            )}
            {matches && (
              <Box marginLeft="auto">
                <MenuIcon
                  onClick={() =>
                    setOpenFeedbackMenu((prevState: boolean) => !prevState)
                  }
                />
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {openFeedbackMenu && matches && (
        <React.Fragment>
          <Box
            className={classes.menuItems}
            style={{ borderTop: '0.3px solid #FFFFFF' }}
          >
            <Typography
              noWrap={true}
              variant="h2"
              textAlign="center"
              color="white"
              style={{ cursor: 'default' }}
            >
              Call Us 1-800-668-2248
            </Typography>
          </Box>
          <Box className={classes.menuItems}>
            <Button variant="outlined" onClick={handleClickOpen}>
              <Typography variant="h2" noWrap color="white">
                ATTRIBUTIONS
              </Typography>
            </Button>
            <Attributions open={open} handleClose={handleClose} />
          </Box>
        </React.Fragment>
      )}
      <Box display="flex" alignItems="center">
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={openFeedback}
          onClose={() => setOpenFeedback(false)}
        >
          <FeedbackForm openFeedback={() => setOpenFeedback(false)} />
        </Modal>
      </Box>
    </Box>
  );
};

export default NavBar;
