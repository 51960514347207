import {
  makeStyles,
  Typography,
  Theme,
  Box,
  useTheme,
  Link,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    fontColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: '4px',
    shadow: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primaryHover,
      color: theme.palette.primary.main,
    },
  },
  innerBoxPadding: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  selected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryHover,
    color: theme.palette.primary.main,
  },
  hyperLink: {
    '&:hover': {
      textDecoration: 'underline',
      color: 'black',
    },
  },
}));

interface IProps {
  title?: string;
  description?: string;
  hyperLinkText?: string;
  selected: boolean;
}

const SelectionCard: React.FC<IProps> = (props: IProps) => {
  const { title, description, hyperLinkText, selected } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card
      sx={{
        border: selected
          ? ` 1px solid ${theme.palette.primary.main}`
          : '1px solid #DDDDDD',
        backgroundColor: selected ? theme.palette.primaryHover : 'none',
        color: selected ? theme.palette.primary.main : 'none',
      }}
      className={classes.root}
    >
      <CardActionArea>
        <CardContent
          className={classes.innerBoxPadding}
          sx={{ cursor: 'pointer' }}
        >
          <Typography variant="bodyBold">{title}</Typography>
          <Box>
            <Typography variant="bodyRegular" whiteSpace="pre-line">
              {description}
            </Typography>
            <Link
              className={classes.hyperLink}
              variant="addEditLink"
              href="/PDFs/FarmRateR1.pdf"
              target="_blank"
            >
              {hyperLinkText}
            </Link>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SelectionCard;
