import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Lottie from 'react-lottie';
import animationData from '../Lotties/4964-check-mark-success-animation.json';
import UserDataContext from '../Context/UserDataContext';
import { ServiceRequestType } from '../Context/Enums';
import { UserDataActionType } from '../Context/UserDataStateReducer';

interface IProps {
  requestType: string;
}

const ThankYou: React.FC<IProps> = (props: IProps) => {
  const { requestType } = props;
  const history = useHistory();
  const { state: userState, dispatch } = useContext(UserDataContext);

  const defaultAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const clearContext = () => {
    dispatch({
      type: UserDataActionType.ClearData,
    });
  };

  const handleNavigationOffPage = () => {
    clearContext();
    history.push('/');
  };

  useEffect(() => {
    const onBackButtonEvent = (e: any) => {
      e.preventDefault();
      handleNavigationOffPage();
    };
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return (
    <Grid container direction="column" alignItems="center" position="absolute">
      <Grid item container>
        <Lottie options={defaultAnimationOptions} height={100} width={100} />
      </Grid>
      <Grid item marginTop="16px" textAlign="center" justifyContent="center">
        <Box>
          <Typography variant="h1">REQUEST SUBMITTED</Typography>
        </Box>
        <Box paddingTop="10px">
          {requestType === ServiceRequestType.NEW_CONNECTION ? (
            <Typography variant="bodyRegular">
              Thank you for your submission, an ATCO representative will contact
              you in the next few business days.
            </Typography>
          ) : (
            <Typography variant="bodySemiBold">
              Thank you for your submission, your quote has been emailed to you.
              <br />
              Please review the email attachment to proceed with the project.{' '}
              <br /> Feel free to call us at 1-800-668-2248 if you have any
              questions.
            </Typography>
          )}
        </Box>
        <Box paddingTop="10px">
          <Typography variant="bold">
            TRACKING ID: {userState.userData.uniqueId}
          </Typography>
        </Box>
      </Grid>
      <Grid item marginTop="16px">
        <Button
          onClick={handleNavigationOffPage}
          disableRipple={true}
          disableTouchRipple={true}
        >
          Submit Another Request
        </Button>
      </Grid>
    </Grid>
  );
};

export default ThankYou;
