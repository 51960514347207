import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const OptionalField: React.FC = () => {
  return (
    <Grid item paddingTop="0px">
      <Typography variant="smallText">Optional</Typography>
    </Grid>
  );
};

export default OptionalField;
