import { IServiceTypeData } from '../../Context/DataTypes';
import { Typography, Divider, Grid } from '@material-ui/core';
import ReviewField from '../common/ReviewField';
import {
  NewConnectionServiceType,
  ServiceRequestType,
} from '../../Context/Enums';
import { vegetationRemoval } from '../../Context/Data';

interface IProps {
  serviceData: IServiceTypeData;
  requestType: string;
  serviceType: string;
  poleType: string;
}

const ReviewElectricInfo: React.FC<IProps> = (props: IProps) => {
  const { requestType, serviceType, poleType, serviceData } = props;

  return (
    <>
      {requestType === ServiceRequestType.NEW_CONNECTION && (
        <>
          {serviceData.serviceEntrance && (
            <ReviewField
              label="Service Entrance"
              content={serviceData.serviceEntrance}
            />
          )}
          {serviceData.breakerSize && (
            <ReviewField
              label="Breaker Size"
              content={serviceData.breakerSize}
            />
          )}

          <ReviewField
            label="Secondary Voltage"
            content={serviceData.secondaryVoltage}
          />
          <ReviewField
            label="Primary Overhead or Underground"
            content={serviceData.primaryLineOverOrUnderground}
          />
          <ReviewField
            label="Primary Line Length"
            content={`${serviceData.primaryLineLength} m`}
          />

          {serviceType === NewConnectionServiceType.RESIDENTIAL ||
            (serviceType === NewConnectionServiceType.FARM && (
              <ReviewField
                label="Secondary Overhead or Underground"
                content={serviceData.secondaryLineOverOrUnderground}
              />
            ))}
          <ReviewField
            label="Remove Vegetation/Brushing"
            content={
              vegetationRemoval.find(
                (veg) => veg.title === serviceData.brushing
              )?.title || ''
            }
          />
          {serviceData.brushingValue > 0 && (
            <ReviewField
              label="Vegetation Removal"
              content={`${serviceData.brushingValue} m`}
            />
          )}
        </>
      )}
      {requestType === ServiceRequestType.NEW_LIGHTING && (
        <>
          <ReviewField label="Pole Type" content={poleType} />
        </>
      )}
    </>
  );
};

export default ReviewElectricInfo;
