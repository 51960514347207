import {
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React, { useEffect } from 'react';
import {
  LSDData,
  meridianData,
  rangeData,
  sectionOptions,
  townshipOptions,
} from '../../Context/Data';
import { ILegalLand } from '../../Context/DataTypes';
import ElectricAPI from '../../API/GlobalApi';

interface IProps {
  legalLand: ILegalLand;
  setLegalLand: (e: any) => void;
}

const LeaglLandForm: React.FC<IProps> = (props: IProps) => {
  const { legalLand, setLegalLand } = props;
  const theme = useTheme();
  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  useEffect(() => {
    if (
      legalLand.LSD &&
      legalLand.SEC &&
      legalLand.TWP &&
      legalLand.RGE &&
      legalLand.MER
    ) {
      ElectricAPI.getLandLocationCoordinates(legalLand).then((data) => {
        //TODO: Add the logic to set the coordinates in the state
      });
    }
  }, [legalLand]);
  return (
    <Grid
      item
      container
      paddingLeft="8px"
      wrap="nowrap"
      width="100%"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        sx={{
          paddingLeft: isMobile ? '2px' : '0px',
          paddingRight: isMobile ? '2px' : '4px',
        }}
      >
        <Grid item container wrap="nowrap">
          <Typography variant="bodyBold">LSD</Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            placeholder="##"
            select
            fullWidth
            value={legalLand.LSD}
            onChange={(e) =>
              setLegalLand({ ...legalLand, LSD: e.target.value })
            }
          >
            {LSDData.map((lsd) => (
              <MenuItem key={lsd.title} value={lsd.value}>
                <Typography variant="bodyRegular">{lsd.title}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{
          paddingLeft: isMobile ? '2px' : '0px',
          paddingRight: isMobile ? '2px' : '4px',
        }}
      >
        <Grid item>
          <Typography variant="bodyBold">Section</Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            placeholder="##"
            select
            fullWidth
            value={legalLand.SEC}
            onChange={(e) =>
              setLegalLand({ ...legalLand, SEC: e.target.value })
            }
          >
            {sectionOptions.map((opt) => (
              <MenuItem key={opt.title} value={opt.value}>
                <Typography variant="bodyRegular">{opt.title}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{
          paddingLeft: isMobile ? '2px' : '0px',
          paddingRight: isMobile ? '2px' : '4px',
        }}
      >
        <Grid item>
          <Typography variant="bodyBold">Township</Typography>
        </Grid>
        <Grid item>
          <Select
            variant="outlined"
            fullWidth
            value={legalLand.TWP}
            onChange={(e) =>
              setLegalLand({ ...legalLand, TWP: e.target.value })
            }
          >
            {townshipOptions.map((town) => (
              <MenuItem key={town.title} value={town.value}>
                <Typography variant="bodyRegular">{town.title}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{
          paddingLeft: isMobile ? '2px' : '0px',
          paddingRight: isMobile ? '2px' : '0px',
        }}
      >
        <Grid item>
          <Typography variant="bodyBold">Range</Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            placeholder="##"
            select
            fullWidth
            value={legalLand.RGE}
            onChange={(e) =>
              setLegalLand({ ...legalLand, RGE: e.target.value })
            }
          >
            {rangeData.map((range) => (
              <MenuItem key={range.title} value={range.value}>
                <Typography variant="bodyRegular">{range.title}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        style={{
          paddingLeft: isMobile ? '2px' : '4px',
          paddingRight: isMobile ? '2px' : '0px',
        }}
      >
        <Grid item>
          <Typography variant="bodyBold">Meridian</Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            placeholder="##"
            select
            fullWidth
            value={legalLand.MER}
            onChange={(e) =>
              setLegalLand({ ...legalLand, MER: e.target.value })
            }
          >
            {meridianData.map((meridian) => (
              <MenuItem key={meridian.title} value={meridian.value}>
                <Typography variant="bodyRegular">{meridian.title}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {/* <GoogleMapImage /> */}
    </Grid>
  );
};

export default LeaglLandForm;
