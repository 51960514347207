import {
  Input,
  InputAdornment,
  Slider,
  Typography,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import PowerLineWarningMessage from '../common/PowerLineWarningMessage';

interface IProps {
  id: string;
  title: string;
  value: any;
  maxValue?: number;
  minValue?: number;
  unit?: string;
  handleValueChange: (e: any) => void;
  showWarning?: boolean;
  warningType?: string;
  secondaryText?: string;
  secondaryNextLine?: string;
}

const SliderInput: React.FC<IProps> = (props: IProps) => {
  const {
    id,
    title,
    value,
    maxValue,
    minValue = 0,
    unit,
    handleValueChange,
    showWarning,
    warningType,
  } = props;
  const theme = useTheme();

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="bodyBold">{title}</Typography>
      </Grid>
      <Grid item container wrap="nowrap" justifyContent="space-between">
        <Grid item width="80%">
          <Slider
            id={id}
            value={value}
            max={maxValue}
            min={minValue}
            onChange={(event: Event) => handleValueChange(event)}
            aria-labelledby="input-slider"
            sx={{ marginLeft: 0 }}
          />
        </Grid>
        <Grid item width="10%">
          <Input
            value={value}
            margin="dense"
            sx={{ padding: theme.spacing(1) }}
            endAdornment={
              <InputAdornment position="end">{unit}</InputAdornment>
            }
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => handleValueChange(event)}
          />
        </Grid>
      </Grid>

      {showWarning && (
        <Grid item marginTop="16px">
          <PowerLineWarningMessage warningType={warningType} />
        </Grid>
      )}
    </Grid>
  );
};

export default SliderInput;
