import React, { createContext } from 'react';
import { IUserDataState, UserDataAction } from './UserDataStateReducer';
import {
  ILocationInfo,
  IRequestInfo,
  IServiceTypeData,
  ISubmissionData,
} from './DataTypes';

const newRequestInfo: IRequestInfo = {
  requestType: '',
  lessThanOneYearService: false,
  onFirstNationLand: false,
  submissionDateAndTime: '',
  preferredStartDate: '',
  serviceType: '',
  newLighting: {
    poleType: '',
  },
};

const newServiceTypeData: IServiceTypeData = {
  breakerSize: '',
  primaryLineOverOrUnderground: '',
  secondaryLineOverOrUnderground: '',
  primaryLineLength: 0,
  secondaryVoltage: '',
  serviceEntrance: '',
  brushing: '',
  brushingValue: 0,
  serviceEntranceA: '',
  operatingLoad: 0,
};

const newLocationInfo: ILocationInfo = {
  locationType: '',
  geometry: {},
  legalLand: {
    LSD: '',
    SEC: '',
    TWP: '',
    RGE: '',
    MER: '',
  },
};

export const newServiceData: ISubmissionData = {
  uniqueId: '',
  requestInfo: newRequestInfo,
  customerInfo: [],
  electricInfo: newServiceTypeData,
  locationInfo: newLocationInfo,
  attachmentPath: '',
  additionalInfo: '',
};

export const initialState: IUserDataState = {
  acceptTermsAndConditions: false,
  userData: newServiceData,
};

const UserDataContext = createContext<{
  state: IUserDataState;
  dispatch: React.Dispatch<UserDataAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export default UserDataContext;
