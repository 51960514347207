import React, { useState } from 'react';
import SentimentVeryDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined';
import MoodBadOutlinedIcon from '@material-ui/icons/MoodBadOutlined';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import InsertEmoticonOutlinedIcon from '@material-ui/icons/InsertEmoticonOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Snackbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TextField } from '@material-ui/core';
import ElectricAPI from '../API/GlobalApi';

interface IProps {
  openFeedback: (shouldOpen: boolean) => void;
}

enum ButtonHoverColors {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  GreenYellow = 'greenyellow',
  Green = 'green',
}

enum ButtonHoverNames {
  VeryPoor = 'Very Poor',
  Poor = 'Poor',
  Average = 'Average',
  Good = 'Good',
  Excellent = 'Excellent',
}

enum FeedbackSubmission {
  error = 'error',
  success = 'success',
}

const renderColorBasedOnButton = (buttonType: string) => {
  let buttonHoverColor = '';
  if (buttonType === ButtonHoverNames.VeryPoor) {
    buttonHoverColor = ButtonHoverColors.Red;
  }
  if (buttonType === ButtonHoverNames.Poor) {
    buttonHoverColor = ButtonHoverColors.Orange;
  }
  if (buttonType === ButtonHoverNames.Average) {
    buttonHoverColor = ButtonHoverColors.Yellow;
  }
  if (buttonType === ButtonHoverNames.Good) {
    buttonHoverColor = ButtonHoverColors.GreenYellow;
  }
  if (buttonType === ButtonHoverNames.Excellent) {
    buttonHoverColor = ButtonHoverColors.Green;
  }

  return buttonHoverColor;
};

const useStyles = (props: { selectedButton: string; hoveredButton: string }) =>
  makeStyles({
    iconContainer: {
      '& .MuiIconButton-label': {
        '&:hover': {
          color: renderColorBasedOnButton(props.hoveredButton),
        },
      },
    },
  });

const FeedbackForm: React.FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const [hoveredIcon, setHoveredIcon] = useState<string>('');
  const [selectedButton, setSelectedButton] = useState<string>('');
  const classes = useStyles({ hoveredButton: hoveredIcon, selectedButton })();
  const [enteredFeedback, setEnteredFeedback] = useState<string>('');
  const [dataSending, setDataSending] = useState<boolean>(false);
  const [snackBar, showSnackBar] = useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(false);

  return (
    <Box
      width="375px"
      height="525px"
      borderRadius="6px"
      padding="16px 24px"
      style={{ backgroundColor: '#FFFFFF' }}
      display="flex"
      flexDirection="column"
    >
      <Box
        marginLeft="auto"
        style={{ color: '#6A6A6A', cursor: 'default' }}
        onClick={() => {
          if (!dataSending) {
            props.openFeedback(false);
          }
        }}
      >
        X
      </Box>
      <Box marginBottom="40px">
        <Typography variant="h2">SHARE YOUR FEEDBACK</Typography>
      </Box>
      <Box marginBottom="10px">
        <Typography variant="bodyRegular">
          How satisfied are you with the electric self serve tool ?
        </Typography>
      </Box>
      <Box display="flex" flexWrap="nowrap" justifyContent="space-evenly">
        <IconButton
          onClick={() => setSelectedButton(ButtonHoverNames.VeryPoor)}
          onMouseEnter={() => setHoveredIcon(ButtonHoverNames.VeryPoor)}
          onMouseLeave={() => setHoveredIcon('')}
          classes={{
            root: classes.iconContainer,
          }}
        >
          <SentimentVeryDissatisfiedOutlinedIcon
            style={{
              width: theme.spacing(6),
              height: theme.spacing(6),
              fill:
                selectedButton === ButtonHoverNames.VeryPoor
                  ? ButtonHoverColors.Red
                  : '',
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => setSelectedButton(ButtonHoverNames.Poor)}
          onMouseEnter={() => setHoveredIcon(ButtonHoverNames.Poor)}
          onMouseLeave={() => setHoveredIcon('')}
          classes={{
            root: classes.iconContainer,
          }}
        >
          <MoodBadOutlinedIcon
            style={{
              width: theme.spacing(6),
              height: theme.spacing(6),
              fill:
                selectedButton === ButtonHoverNames.Poor
                  ? ButtonHoverColors.Orange
                  : '',
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => setSelectedButton(ButtonHoverNames.Average)}
          onMouseEnter={() => setHoveredIcon(ButtonHoverNames.Average)}
          onMouseLeave={() => setHoveredIcon('')}
          classes={{
            root: classes.iconContainer,
          }}
        >
          <FaceOutlinedIcon
            style={{
              width: theme.spacing(6),
              height: theme.spacing(6),
              fill:
                selectedButton === ButtonHoverNames.Average
                  ? ButtonHoverColors.Yellow
                  : '',
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => setSelectedButton(ButtonHoverNames.Good)}
          onMouseEnter={() => setHoveredIcon(ButtonHoverNames.Good)}
          onMouseLeave={() => setHoveredIcon('')}
          classes={{
            root: classes.iconContainer,
          }}
        >
          <InsertEmoticonOutlinedIcon
            style={{
              width: theme.spacing(6),
              height: theme.spacing(6),
              fill:
                selectedButton === ButtonHoverNames.Good
                  ? ButtonHoverColors.GreenYellow
                  : '',
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => setSelectedButton(ButtonHoverNames.Excellent)}
          onMouseEnter={() => setHoveredIcon(ButtonHoverNames.Excellent)}
          onMouseLeave={() => setHoveredIcon('')}
          classes={{
            root: classes.iconContainer,
          }}
        >
          <SentimentSatisfiedOutlinedIcon
            style={{
              width: theme.spacing(6),
              height: theme.spacing(6),
              fill:
                selectedButton === ButtonHoverNames.Excellent
                  ? ButtonHoverColors.Green
                  : '',
            }}
          />
        </IconButton>
      </Box>
      <Box textAlign="center" marginBottom="40px">
        {hoveredIcon !== '' && (
          <Typography variant="smallText">{hoveredIcon}</Typography>
        )}
        {selectedButton !== '' && hoveredIcon === '' && (
          <Typography variant="smallText">{selectedButton}</Typography>
        )}
      </Box>
      <Box marginBottom="10px">
        <TextField
          fullWidth
          multiline
          rows={4}
          value={enteredFeedback}
          onChange={(event: any) => setEnteredFeedback(event.target.value)}
          placeholder="Type your feedback here"
        />
      </Box>
      <Box marginTop="auto">
        {!dataSending && (
          <Button
            variant="main"
            onClick={async () => {
              await submitFeedback();
            }}
            disabled={selectedButton === ''}
          >
            <Typography variant="bodyRegular">Submit</Typography>
          </Button>
        )}
        {dataSending && <CircularProgress color="primary" />}
      </Box>
      {snackBar === FeedbackSubmission.success && (
        <Snackbar
          open={showNotification}
          autoHideDuration={4500}
          onClose={() => setShowNotification(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={() => setShowNotification(false)} severity="success">
            <Typography variant="bodyRegular">
              Successfully submitted feedback!
            </Typography>
          </Alert>
        </Snackbar>
      )}
      {snackBar === FeedbackSubmission.error && (
        <Snackbar
          open={showNotification}
          autoHideDuration={4500}
          onClose={() => setShowNotification(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={() => setShowNotification(false)} severity="error">
            <Typography variant="bodyRegular">
              Something went wrong when submitting feedback!
            </Typography>
          </Alert>
        </Snackbar>
      )}
    </Box>
  );

  async function submitFeedback() {
    setDataSending(true);
    await ElectricAPI.submitFeedback({
      rating: selectedButton,
      feedback: enteredFeedback,
    })
      .then((data: any) => {
        setDataSending(false);
        showSnackBar(FeedbackSubmission.success);
        setShowNotification(true);
        setEnteredFeedback('');
        setSelectedButton('');
      })
      .catch((error) => {
        console.log('error', error);
        setDataSending(false);
        showSnackBar(FeedbackSubmission.error);
        setShowNotification(true);
      });
  }
};

export default FeedbackForm;
