import produce from 'immer';
import {
  ICustomerInformation,
  ILocationInfo,
  IRequestInfo,
  IServiceTypeData,
  ISubmissionData,
} from './DataTypes';
import { initialState, newServiceData } from './UserDataContext';

export interface IUserDataState {
  acceptTermsAndConditions: boolean;
  userData: ISubmissionData;
}

export enum UserDataActionType {
  AcceptTermsAndConditions,
  RequestInfo,
  RequestInfoBasic,
  PoleType,
  RequestRequestType,
  LocationInfo,
  UserUniqueId,
  ElectricServiceInfo,
  DocumentRequestData,
  SetCustomersInfo,
  ClearData,
}

export type UserDataAction =
  | {
      type: UserDataActionType.AcceptTermsAndConditions;
      payload: { acceptTermsAndConditions: boolean };
    }
  | {
      type: UserDataActionType.RequestRequestType;
      payload: { requestRequestType: string };
    }
  | {
      type: UserDataActionType.PoleType;
      payload: { poleType: string };
    }
  | {
      type: UserDataActionType.RequestInfo;
      payload: { requestInfo: IRequestInfo };
    }
  | {
      type: UserDataActionType.RequestInfoBasic;
      payload: {
        serviceType: string;
        lessThanOneYear: boolean;
        onFirstNationLand: boolean;
        poleType: string;
      };
    }
  | {
      type: UserDataActionType.LocationInfo;
      payload: { locationInfo: ILocationInfo };
    }
  | {
      type: UserDataActionType.UserUniqueId;
      payload: { uniqueId: string };
    }
  | {
      type: UserDataActionType.ElectricServiceInfo;
      payload: { electricInfo: IServiceTypeData };
    }
  | {
      type: UserDataActionType.DocumentRequestData;
      payload: { path: string; startDate: string; additionalInfo: string };
    }
  | {
      type: UserDataActionType.SetCustomersInfo;
      payload: { customersInfo: ICustomerInformation[] };
    }
  | {
      type: UserDataActionType.ClearData;
    };

const userDataStateReducer = (
  state: IUserDataState,
  action: UserDataAction
): IUserDataState =>
  produce(state, (draft) => {
    switch (action.type) {
      case UserDataActionType.AcceptTermsAndConditions:
        draft.acceptTermsAndConditions =
          action.payload.acceptTermsAndConditions;
        break;
      case UserDataActionType.RequestRequestType:
        draft.userData.requestInfo.requestType =
          action.payload.requestRequestType;
        break;
      case UserDataActionType.RequestInfo:
        draft.userData.requestInfo = action.payload.requestInfo;
        break;
      case UserDataActionType.RequestInfoBasic:
        draft.userData.requestInfo.serviceType = action.payload.serviceType;
        draft.userData.requestInfo.lessThanOneYearService =
          action.payload.lessThanOneYear;
        draft.userData.requestInfo.onFirstNationLand =
          action.payload.onFirstNationLand;
        draft.userData.requestInfo.newLighting.poleType =
          action.payload.poleType;
        break;
      case UserDataActionType.PoleType:
        draft.userData.requestInfo.newLighting.poleType =
          action.payload.poleType;
        break;
      case UserDataActionType.UserUniqueId:
        draft.userData.uniqueId = action.payload.uniqueId;
        break;
      case UserDataActionType.ElectricServiceInfo:
        draft.userData.electricInfo = action.payload.electricInfo;
        break;
      case UserDataActionType.DocumentRequestData:
        draft.userData.attachmentPath = action.payload.path;
        draft.userData.requestInfo.preferredStartDate =
          action.payload.startDate;
        draft.userData.additionalInfo = action.payload.additionalInfo;
        break;
      case UserDataActionType.SetCustomersInfo:
        draft.userData.customerInfo = action.payload.customersInfo;
        break;
      case UserDataActionType.LocationInfo:
        draft.userData.locationInfo = action.payload.locationInfo;
        break;
      case UserDataActionType.ClearData:
        draft.acceptTermsAndConditions = false;
        draft.userData = newServiceData;
        break;
      default:
        break;
    }
  });

export default userDataStateReducer;
