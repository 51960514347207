import {
  Box,
  Checkbox,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React, { useContext, useState } from 'react';
import SiteContent from '../SiteContent/SiteContent';
import UserDataContext from '../Context/UserDataContext';
import PreviousNextButtons from '../Components/common/PreviousNextButtons';
import { useHistory } from 'react-router-dom';
import NewConnectionRequiredInfo from './NewConnectionRequiredInfo';
import NewLightingRequiredInfo from './NewLightingRequiredInfo';
import { UserDataActionType } from '../Context/UserDataStateReducer';
import { RequestTypePath } from '../Routes/Routes';

interface IProps {
  requestType: string;
}

const RequiredInfo: React.FC<IProps> = (props: IProps) => {
  const { requestType } = props;

  const theme = useTheme();
  const history = useHistory();

  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));

  const { state: userState, dispatch } = useContext(UserDataContext);
  const [isFirstNationLand, setIsFirstNationLand] = useState(false);
  const [isLessThanYear, setIsLessThanYear] = useState(false);
  const [selectedNewService, setSelectedNewService] = useState(
    userState.userData.requestInfo.serviceType
  );
  const [selectedPoleType, setSelectedPoleType] = useState(
    userState.userData.requestInfo.newLighting.poleType
  );

  const handleNextPage = () => {
    dispatch({
      type: UserDataActionType.RequestInfoBasic,
      payload: {
        serviceType: selectedNewService,
        lessThanOneYear: isLessThanYear,
        onFirstNationLand: isFirstNationLand,
        poleType: selectedPoleType,
      },
    });

    if (requestType === RequestTypePath.NEW_LIGHTING) {
      history.push(`/request-type/${requestType}/site-information`);
    } else {
      history.push(
        `/request-type/${requestType}/${selectedNewService}/required-info`
      );
    }
  };

  const nextButtonDisabled =
    (requestType === RequestTypePath.NEW_CONNECTION &&
      selectedNewService === '') ||
    (requestType === RequestTypePath.NEW_LIGHTING && selectedPoleType === '');

  return (
    <Grid
      container
      alignItems="center"
      style={{ marginLeft: isMobile ? '16px' : '0px' }}
      paddingLeft="8px"
    >
      <Grid item container>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item sx={{ marginBottom: '32px' }}>
            <Typography variant="h1">
              {SiteContent.requiredType.title}
            </Typography>
          </Grid>
          {requestType === RequestTypePath.NEW_CONNECTION && (
            <NewConnectionRequiredInfo
              newServiceType={selectedNewService}
              setNewServiceType={setSelectedNewService}
            />
          )}
          {requestType === RequestTypePath.NEW_LIGHTING && (
            <NewLightingRequiredInfo
              newServiceType={selectedPoleType}
              setNewServiceType={setSelectedPoleType}
            />
          )}
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" alignItems="center" marginTop="32px">
        <Grid item>
          <Checkbox
            checked={isLessThanYear}
            color="primary"
            onClick={(e: any) => {
              setIsLessThanYear(e.target.checked);
            }}
          />
        </Grid>
        <Grid item alignSelf="center">
          <Box>
            <Typography variant="bodyBold" sx={{ fontSize: '16px' }}>
              This service is going to be in place less than 1 year
            </Typography>
          </Box>
          <Box>
            <Typography variant="bodyRegular" sx={{ fontSize: '16px' }}>
              No investment on services that will be in place for less than one
              year
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" alignItems="center" marginTop="32px">
        <Grid item>
          <Checkbox
            checked={isFirstNationLand}
            color="primary"
            onClick={(e: any) => {
              setIsFirstNationLand(e.target.checked);
            }}
          />
        </Grid>
        <Grid item alignSelf="center">
          <Box>
            <Typography variant="bodyBold" sx={{ fontSize: '16px' }}>
              Is the project on the Reserve lands of a First Nation?
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" alignItems="center" marginTop="32px">
        <Grid item alignSelf="center">
          <Typography variant="smallText">
            ATCO investment on the service is calculated based on your rate
            class and load of your contract duration. For detailed information,
            please use the following links - link to the{' '}
          </Typography>
          <Link
            sx={{ fontSize: '12px' }}
            variant="addEditLink"
            borderBottom="0.5px solid #0057B8"
            href="/PDFs/PriceSchedule.pdf"
            target="_blank"
          >
            price schedules
          </Link>
          <Typography variant="smallText">
            {' '}
            (rate class) and link to{' '}
          </Typography>
          <Link
            sx={{ fontSize: '12px' }}
            variant="addEditLink"
            borderBottom="0.5px solid #0057B8"
            href="/PDFs/ScheduleInvestment.pdf"
            target="_blank"
          >
            schedule of available investment
          </Link>
          <Typography variant="smallText">.</Typography>
        </Grid>
      </Grid>
      <PreviousNextButtons
        nextDisabled={nextButtonDisabled}
        clickNextHandler={handleNextPage}
      />
    </Grid>
  );
};

export default RequiredInfo;
