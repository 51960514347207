const siteContent = {
  welcome: {
    title: 'WELCOME TO ATCO ELECTRICITY' + "'" + 'S SELF SERVE PORTAL',
    titleSmall: 'What we need to get started:',
    listTitle1: 'Site location',
    listTitle1Description:
      'Please provide the legal land description. You can find this on your property tax statement. Alternatively you can use a GPS coordinate, you can get this from Google maps.',
    listTitle2: 'Voltage and service size you require.',
    listTitle2Description:
      'Our Customer Operating Load Brochure will describe what you need to know about your voltage and service size. If you are unsure, your electrician will have this information.',
    listTtile3: 'Site Plan.',
    listTitle3Description:
      ' A diagram of your site including the proposed transformer location and requested powerline route. A PDF or AutoCAD (DWG) or JPEG format files are accepted. Please see the example in the link below.',
    listTitle4: 'Single Line Diagram (SLD). ',
    listTitle4Description:
      'A symbolic representation of your power system (For oilfield customers only).',
    listTitle5: 'Email address. ',
    listTitle5Description:
      'A proposal letter will be emailed to the email address provided.',

    terms:
      'Terms and conditions and any other disclaimer text needed to be checked off before submitting.',
    download: 'Supporting Forms',
    start: 'Start Quote',
  },
  requestType: {
    title: 'WHAT TYPE OF SERVICE ARE YOU LOOKING FOR?',
    breakerText:
      'Make changes to an existing farm breaker. Please note this is an intake only, ATCO will contact you to review contracts, pricing and investment.',
    newLighting: `This is for new lighting on private residence. If you require street lighting please call us at 
      1-800-668-2248.
      Please note: This tool is for an Invested Option meaning the lighting fixtures are installed, owned and maintained by ATCO Electric. ATCO Electric is responsible for the full cost of life replacement. If you require a Non-Invested option, please call us.`,
    newConntection:
      'Set up a new electrical service to an un-serviced property.',
    next: 'Next Step',
    previous: 'Previous Step',
  },
  requiredType: {
    title: 'WHAT TYPE OF NEW SERVICE IS REQUIRED?',
    residentialText:
      'This type of service is available for single and separate households through a single-phase service at secondary voltage (120/240V) through a single meter.',
    farmText:
      'This type of service is only for farming operations, proof of farming is required.',
    generalServiceText:
      'This type of service is available for customers whose service is below 500 kW. Typical commercial customers such as a store, restaurant or a warehouse.',
    oilfield:
      'This rate is available only for customers with production energy requirements in the petroleum and natural gas industries including related operations with yearly average operating demand of less than 75 kilowatts.',
    largeGeneralService:
      'This type of service is available for customers whose service is greater than 500 kW. Typical customers may be a gas plant, a large store such as a big box store, or oilsands facilities, etc.',
    next: 'NEXT STEP',
    previous: 'PREVIOUS STEP',
    breakOptions: {
      breaker30: '30A',
      breaker35: '35A',
      breaker50: '50A',
      breaker80: '80A',
      breaker100: '100A',
      breaker200: '200A',
    },
    woodOptions: {
      existinPoleText: '',
      woodPoleText: '',
    },
    voltageOptions: {
      phase1Wire3Text: '120/240 Volt, 1 Phase',
      phase3Wire4Text: '120/208 Volt, 3 Phase, WYE',
      phase3Wire4VoltText: '277/480 Volt, 3 Phase, WYE',
      phase3Wire3Volt600Text: '347/600 Volt, 3 Phase, WYE',
    },
    lineOptions: {
      undergroundText: 'Underground – Padmount Transformer',
      overheadText: 'Overhead – Polemount Transformer',
    },
    breakerSizeTitle: 'What is your breaker size ?',
    existingBreakerTitle: 'Existing breaker size ?',
    newBreakerTitle: 'New breaker size ?',
    operatingLoadTitle: 'What is your operating load ?',
    secondaryVoltageTitle: 'What is your secondary voltage ?',
    overheadUndergroundPrimaryTitle:
      'Will the primary line be overhead or underground',
    overheadUndergroundSecondaryTitle:
      'Will the secondary line be overhead or underground',
    overheadUndergroundSecondaryLineTitle:
      'What is the length of the secondary line meters?',
    overheadUndergroundPrimaryLineTitle:
      'What is the length of the primary line in meters?',
    idk: "I don't know",
    cabinetAndCableText: 'Does the cabinet & cable need to be replaced ?',
  },
  siteInformation: {
    title: 'WHAT TYPE OF NEW SERVICE ARE YOU LOOKING FOR ?',
    locationTitle: 'What type of location ?',
    locationOptions: {
      legalLand: 'Legal Land Location',
      civicAddress: 'Civic Address',
    },
  },
  contactNumber: '1-800-668-2248',
};

export default siteContent;
