import { Grid } from '@material-ui/core';

import React from 'react';
import ATCOButton from './ATCOButton';
import { useHistory } from 'react-router-dom';

interface IProps {
  backButtonName?: string;
  clickBackHandler?: () => void;
  nextButtonName?: string;
  nextDisabled: boolean;
  clickNextHandler: () => void;
}

const PreviousNextButtons: React.FC<IProps> = (props: IProps) => {
  const {
    backButtonName = 'Previous Step',
    clickBackHandler,
    nextButtonName = 'Next Step',
    nextDisabled,
    clickNextHandler,
  } = props;

  const history = useHistory();

  const defaultHandler = () => {
    history.goBack();
  };
  return (
    <Grid
      item
      container
      width="100%"
      wrap="nowrap"
      justifyContent="space-between"
      marginTop="32px"
    >
      <Grid item>
        <ATCOButton
          isnext={false}
          onClick={clickBackHandler || defaultHandler}
          buttonname={backButtonName}
        />
      </Grid>
      <Grid item>
        <ATCOButton
          onClick={clickNextHandler}
          disabled={nextDisabled}
          buttonname={nextButtonName}
        />
      </Grid>
    </Grid>
  );
};

export default PreviousNextButtons;
