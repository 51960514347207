import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React, { useContext, useState } from 'react';
import SelectionCard from '../Components/SelectionCard';
import SiteContent from '../SiteContent/SiteContent';
import UserDataContext from '../Context/UserDataContext';
import { useHistory } from 'react-router-dom';
import PreviousNextButtons from '../Components/common/PreviousNextButtons';
import { UserDataActionType } from '../Context/UserDataStateReducer';
import { ServiceRequestType } from '../Context/Enums';
import { requestTypeData } from '../Context/Data';

const RequestType: React.FC = () => {
  const { state: userState, dispatch } = useContext(UserDataContext);
  const history = useHistory();
  const theme = useTheme();
  const [requestRequestType, setRequestRequestType] = useState(
    userState.userData.requestInfo.requestType
  );

  const isMobile = useMediaQuery(theme.breakpoints.down(1200));

  const handlePreviousPage = () => {
    history.push('/');
  };

  const handleNextPage = () => {
    dispatch({
      type: UserDataActionType.RequestRequestType,
      payload: { requestRequestType: requestRequestType },
    });
    if (requestRequestType === ServiceRequestType.BREAKER_CHANGE) {
      history.push('/request-type/breaker-change/site-information');
    } else {
      history.push(`/request-type/${requestRequestType}/required-info`);
    }
  };

  return (
    <Grid container marginLeft={isMobile ? '16px' : '0px'} paddingLeft="8px">
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        spacing={2}
      >
        <Grid item sx={{ marginBottom: '32px' }}>
          <Typography variant="h1">{SiteContent.requestType.title}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            {requestTypeData.map((request: any) => (
              <Grid
                item
                key={request.key}
                onClick={() => {
                  setRequestRequestType(request.key);
                }}
              >
                <SelectionCard
                  selected={requestRequestType === request.key}
                  title={request.title}
                  description={request.description}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <PreviousNextButtons
        clickBackHandler={handlePreviousPage}
        nextDisabled={requestRequestType === ''}
        clickNextHandler={handleNextPage}
      />
    </Grid>
  );
};

export default RequestType;
