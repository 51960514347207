import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import BaseTheme from './Themes/ThemeProvider';
import Layout from './Components/Layout';
import QuotingContainer from './Containers/QuotingContainer';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Routes from './Routes/Routes';
import './App.css';
import ElectricProvider from './Context/ElectricContext';
import AdapterDateFns from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import SiteDocumentDataProvider from './Context/SiteDocumentsContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { auth } from './API/firebase';
import UserDataContextProvider from './Context/UserDataContextProvider';

auth
  .signInAnonymously()
  .then((userCredential) => {
    const user = userCredential.user;
    console.log('logged in:', user);
  })
  .catch((error) => {
    console.error('Error signing in anonymously:', error);
  });

const App: React.FC = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      language="English (US)"
      useRecaptchaNet={true}
      useEnterprise={true}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <UserDataContextProvider>
        <MuiThemeProvider theme={BaseTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Router>
              <ElectricProvider>
                <SiteDocumentDataProvider>
                  <Layout>
                    <QuotingContainer>
                      <Route component={Routes} />
                    </QuotingContainer>
                  </Layout>
                </SiteDocumentDataProvider>
              </ElectricProvider>
            </Router>
          </LocalizationProvider>
        </MuiThemeProvider>
      </UserDataContextProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
