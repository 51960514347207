import { MenuItem, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { IDropdownOption } from '../../Context/DataTypes';

interface IProps {
  id: string;
  title: string;
  options: IDropdownOption[];
  dropdownValue: string;
  disableOption?: boolean;
  handleValueChange: (e: any) => void;
  secondaryText?: string;
  secondaryNextLine?: string;
}

const DropdownQuestion: React.FC<IProps> = (props: IProps) => {
  const {
    id,
    title,
    dropdownValue,
    options,
    disableOption,
    handleValueChange,
    secondaryText,
    secondaryNextLine,
  } = props;

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="bodyBold">{title}</Typography>
      </Grid>
      <Grid item>
        <TextField
          id={id}
          fullWidth
          value={dropdownValue}
          onChange={(e: any) => handleValueChange(e)}
          variant="outlined"
          select
        >
          {options.map((opt) => (
            <MenuItem
              value={opt.title}
              key={opt.title}
              disabled={opt.disabled && disableOption}
            >
              <Typography variant="bodyRegular">{opt.description}</Typography>
            </MenuItem>
          ))}
        </TextField>
        {secondaryText && (
          <Grid item paddingTop="0px">
            <Typography variant="smallText">{secondaryText}</Typography>
          </Grid>
        )}
        {secondaryText && (
          <Grid item paddingTop="0px">
            <Typography variant="smallText">{secondaryNextLine}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DropdownQuestion;
