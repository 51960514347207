import firebase from '../API/firebase';
import { FileObject } from 'material-ui-dropzone';

interface IFirebaseResult {
  success: boolean;
  downloadUrl: string;
}

const useFirebaseStorage = () => {
  const uploadDocuments = async (
    document: FileObject,
    uniqueID: string
  ): Promise<IFirebaseResult> => {
    let result: IFirebaseResult = { success: false, downloadUrl: '' };

    const updatedFile = {
      data: document.data,
      file: {
        ...document.file,
        lastModified: document.file.lastModified,
        size: document.file.size,
        type: document.file.type,
        name: `userDoc/${uniqueID}/${document.file.name}`,
        uniqueID: uniqueID,
      } as File,
    } as FileObject;

    const storageRef = firebase.storage().ref().child(updatedFile.file.name);
    const data = updatedFile.data ? updatedFile.data.toString() : '';

    await storageRef
      .putString(data, 'data_url')
      .then(() => storageRef.getDownloadURL())
      .then((url: string) => {
        result = {
          success: true,
          downloadUrl: url,
        };
      })
      .catch((e) => {
        console.error(e);
        result = {
          success: false,
          downloadUrl: '',
        };
      });
    return result;
  };

  const removeDocuments = async (
    document: FileObject,
    uniqueID: string
  ): Promise<boolean> => {
    let result = false;
    const storageRef = firebase
      .storage()
      .ref()
      .child(`userDoc/${uniqueID}/${document.file.name}`);

    await storageRef
      .delete()
      .then(() => {
        result = true;
      })
      .catch((error) => {
        console.log('error', error);
        result = false;
      });

    return result;
  };

  return { uploadDocuments, removeDocuments };
};

export default useFirebaseStorage;
