import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface IProps {
  label: string;
  content: string;
}

const ReviewField: React.FC<IProps> = (props: IProps) => {
  const { label, content = '' } = props;
  return (
    <Grid item container justifyContent="space-between">
      <Grid item>
        <Typography variant="bodyRegular">{label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="bodyRegular">{content}</Typography>
      </Grid>
    </Grid>
  );
};

export default ReviewField;
