import { InputAdornment, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { ICustomerInformation } from '../../Context/DataTypes';
import { ServiceRequestType } from '../../Context/Enums';
import SiteIdDialog from '../common/SiteIdDialog';
import FormInput from '../common/FormInput';
import {
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
  validateSitId,
} from '../helpers/util';

interface IProps {
  landOwner: ICustomerInformation;
  handleLandOwner: (cust: ICustomerInformation) => void;
  requestType: string;
}

const LegalLandOwnerForm: React.FC<IProps> = (props: IProps) => {
  const { landOwner, handleLandOwner, requestType } = props;

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h2">Contact Information</Typography>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        {requestType === ServiceRequestType.BREAKER_CHANGE && (
          <Grid item container>
            <Grid item>
              <Typography variant="bodyBold">Site ID</Typography>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <TextField
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="bodyRegular">001</Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={landOwner.siteID}
                  onChange={(e) =>
                    handleLandOwner({
                      ...landOwner,
                      siteID: e.target.value,
                    })
                  }
                  error={validateSitId(landOwner.siteID)}
                  helperText={
                    validateSitId(landOwner.siteID) &&
                    'Invalid Site Id. Must be 10 digits after "001"'
                  }
                />
              </Grid>
            </Grid>
            <SiteIdDialog />
          </Grid>
        )}
        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="First Name"
            value={landOwner.firstName}
            handleOnChange={(e) =>
              handleLandOwner({
                ...landOwner,
                firstName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="Last Name"
            value={landOwner.lastName}
            handleOnChange={(e) =>
              handleLandOwner({
                ...landOwner,
                lastName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="Company Name"
            value={landOwner.companyName}
            handleOnChange={(e) => {
              handleLandOwner({
                ...landOwner,
                companyName: e.target.value,
              });
            }}
            showOptional
          />
        </Grid>

        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="Phone Number"
            value={landOwner.phoneNumber}
            handleOnChange={(e) =>
              handleLandOwner({
                ...landOwner,
                phoneNumber: e.target.value,
              })
            }
            error={validatePhoneNumber(landOwner.phoneNumber)}
            helperText={
              validatePhoneNumber(landOwner.phoneNumber) &&
              'Invalid Phone Number Format. Must follow format 123-456-7890 or 1234567890'
            }
          />
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="Email Address"
            value={landOwner.emailAddress}
            handleOnChange={(e) =>
              handleLandOwner({
                ...landOwner,
                emailAddress: e.target.value,
              })
            }
            placeholder="A proposal letter will be emailed to the email address provided"
            error={validateEmail(landOwner.emailAddress)}
            helperText={
              validateEmail(landOwner.emailAddress) &&
              'Invalid email address format. Must be in the form of abc@example.com'
            }
          />
        </Grid>

        <Grid item container direction="column" spacing={1}>
          <FormInput
            label="Street Address"
            placeholder="Enter street address"
            value={landOwner.streetAddress}
            handleOnChange={(e) =>
              handleLandOwner({
                ...landOwner,
                streetAddress: e.target.value,
              })
            }
          />
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            paddingLeft: '16px',
            paddingTop: '16px',
          }}
        >
          <Grid item width="50%" sx={{ paddingRight: '16px' }}>
            <FormInput
              label="City"
              placeholder="Calgary"
              value={landOwner.city}
              handleOnChange={(e) =>
                handleLandOwner({
                  ...landOwner,
                  city: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item width="50%">
            <FormInput
              label="Postal Code"
              placeholder="X1X 1X1"
              value={landOwner.postalCode}
              handleOnChange={(e) =>
                handleLandOwner({
                  ...landOwner,
                  postalCode: e.target.value,
                })
              }
              error={validatePostalCode(landOwner.postalCode)}
              helperText={
                validatePostalCode(landOwner.postalCode) &&
                'Invalid postal code. Must be in the form of A1A 1A1 or a1a1a1'
              }
              forceUpperCase
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LegalLandOwnerForm;
