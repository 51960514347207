import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Modal, useMediaQuery, useTheme } from '@material-ui/core';
import Stepper from '../Components/Stepper';
import { useLocation } from 'react-router-dom';
import useRefreshPrompt from '../Hooks/useRefreshPrompt';
import { electricData } from '../Context/ElectricContext';
import FeedbackForm from '../Components/FeedbackForm';

interface IProps {
  children?: React.ReactNode;
}

const QuotingContainer: React.FC<IProps> = (props: IProps) => {
  const location = useLocation();
  const path = location.pathname;
  const theme = useTheme();
  const data = useContext(electricData);

  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));

  const setShowPrompt = useRefreshPrompt(true);
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);
  const footer = renderFooterInQuoteContainer(isMobile, path);

  useEffect(() => {
    return () => {
      setShowPrompt(false);
    };
  }, []);

  return (
    <>
      <Grid
        container
        item
        direction={isMobile ? 'column' : 'row'}
        alignItems="baseline"
        wrap="nowrap"
      >
        {path !== '/thank-you' && (
          <>
            <Grid
              item
              container
              justifyContent="center"
              style={{ flexBasis: isMobile ? 'none' : '25%' }}
              onClick={() => setShowPrompt(true)}
              marginRight="16px"
            >
              <Stepper />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              paddingRight="96px"
              flexBasis="75%"
              style={{
                paddingRight: isMobile ? '24px' : '96px',
              }}
            >
              {props.children}
            </Grid>
          </>
        )}

        {path === '/thank-you' && (
          <Grid
            item
            container
            justifyContent="center"
            style={{ overflowY: 'hidden' }}
          >
            {props.children}
          </Grid>
        )}
        <Grid item alignItems="center">
          <Modal
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            open={openFeedbackModal}
            onClose={() => setOpenFeedbackModal(false)}
          >
            <FeedbackForm openFeedback={setOpenFeedbackModal} />
          </Modal>
        </Grid>
      </Grid>
      {footer}
    </>
  );

  function renderFooterInQuoteContainer(isSmallScreen: boolean, path: string) {
    let component = <React.Fragment></React.Fragment>;
    if (path === '/') {
      component = (
        <Box
          display="flex"
          flexDirection="column"
          marginTop="auto"
          id="INCONTAINER"
        ></Box>
      );
    }

    if (
      path.includes(`/required-info/${data.serviceType.replace(/ /g, '')}/2`) &&
      !isSmallScreen
    ) {
      component = (
        <Box
          display="flex"
          flexDirection="column"
          marginTop="auto"
          id="INCONTAINER"
        ></Box>
      );
    }

    if (path.includes(`/site-information`) && !isSmallScreen) {
      component = (
        <Box
          display="flex"
          flexDirection="column"
          marginTop="auto"
          id="INCONTAINER"
        ></Box>
      );
    }

    if (path.includes('land-owner') && !isSmallScreen) {
      component = <Grid item container marginTop="16px" bottom={0}></Grid>;
    }

    if (path.includes('review') && !isSmallScreen) {
      component = <Grid item container marginTop="16px" bottom={0}></Grid>;
    }

    return component;
  }
};

export default QuotingContainer;
