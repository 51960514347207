import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { stepperData } from '../Context/Data';
import { useLocation } from 'react-router-dom';

const StepperPaths = [
  {
    step: 2,
    path: 'request-type',
  },
  {
    step: 3,
    path: 'required-info',
  },
  {
    step: 4,
    path: 'site-information',
  },
  {
    step: 5,
    path: 'upload-documents',
  },
  {
    step: 6,
    path: 'land-owner-info',
  },
  {
    step: 7,
    path: 'review',
  },
];

const StepperComponent: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const foundStep = StepperPaths.find((step) =>
      location.pathname.split('/').pop()?.includes(step.path)
    );
    if (foundStep) {
      setActiveStep(foundStep.step);
    } else {
      setActiveStep(1);
    }
  }, [activeStep, location.pathname]);

  return (
    <Grid
      container
      item
      direction="column"
      width="247px"
      marginRight="auto"
      style={{
        paddingLeft: isMobile ? '24px' : '80px',
        paddingRight: isMobile ? '0px' : '80px',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: isMobile ? 'flex-start' : 'center',
      }}
    >
      {stepperData.map((stepper) => {
        return (
          <Grid
            item
            container
            alignItems="center"
            wrap="nowrap"
            justifyContent="flex-start"
            style={{
              padding: isMobile ? '0px' : '16px',
              marginBottom: isMobile ? '16px' : '0px',
            }}
            key={stepper.step}
          >
            <Grid item>
              <Avatar
                style={{
                  backgroundColor:
                    activeStep === stepper.step
                      ? theme.palette.primary.main
                      : theme.palette.extraColors.lightGrey,
                  width: '25px',
                  height: '25px',
                  fontSize: '1.15rem',
                  color: theme.palette.extraColors.white,
                }}
              >
                {activeStep === stepper.step ? (
                  <Typography variant="bodyRegular">{stepper.step}</Typography>
                ) : (
                  <CheckCircleIcon color="disabled" />
                )}
              </Avatar>
            </Grid>
            <Grid item alignItems="flex-start" marginLeft={1} flexShrink={0}>
              <Typography
                variant={
                  activeStep === stepper.step ? 'bodyBold' : 'bodySemiBold'
                }
                sx={{
                  color:
                    activeStep === stepper.step
                      ? theme.palette.extraColors.black
                      : theme.palette.extraColors.lightGrey,
                  flexShrink: 0,
                }}
              >
                {stepper.title}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default StepperComponent;
