import { Grid, TextField, TextFieldProps, Typography } from '@material-ui/core';
import React from 'react';
import OptionalField from './OptionalField';

interface IProps {
  label: string;
  value: string | undefined;
  handleOnChange: (e: any) => void;
  handleOnBlur?: (e: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  showOptional?: boolean;
  placeholder?: string;
  forceUpperCase?: boolean;
}

const FormInput: React.FC<IProps> = (props: IProps) => {
  const {
    label,
    value,
    handleOnChange,
    handleOnBlur,
    error,
    helperText,
    placeholder,
    showOptional = false,
    forceUpperCase = false,
  } = props;
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="bodyBold">{label}</Typography>
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          value={value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          inputProps={{
            style: { textTransform: forceUpperCase ? 'uppercase' : 'none' },
          }}
        />
      </Grid>
      {showOptional && <OptionalField />}
    </Grid>
  );
};

export default FormInput;
