import { createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    primaryLink: string;
    primaryLinkHighlight: string;
    primaryShadow: string;
    secondaryShadow: string;
    primaryHover: string;
    secondaryHover: string;
    primaryFontColor: string;
    secondaryFontColor: string;
    tertiary: {
      main: string;
    };
    tertiaryHover: string;
    tertiaryShadow: string;
    extraColors: {
      [key: string]: string;
    };
  }

  interface PaletteOptions {
    primaryLink: string;
    primaryLinkHighlight: string;
    primaryShadow: string;
    secondaryShadow: string;
    primaryHover: string;
    secondaryHover: string;
    primaryFontColor: string;
    secondaryFontColor: string;
    tertiary: {
      main: string;
    };
    tertiaryHover: string;
    tertiaryShadow: string;
    extraColors: {
      [key: string]: string;
    };
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    bodyBold: React.CSSProperties;
    sidebarBold: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    disabledRegular: React.CSSProperties;
    sidebarRegular: React.CSSProperties;
    bodySemiBold: React.CSSProperties;
    smallText: React.CSSProperties;
    warning: React.CSSProperties;
    addEditLink: React.CSSProperties;
    bold: React.CSSProperties;
  }

  interface TypographyOptions {
    bodyBold?: React.CSSProperties;
    sidebarBold?: React.CSSProperties;
    bodyRegular?: React.CSSProperties;
    disabledRegular?: React.CSSProperties;
    sidebarRegular?: React.CSSProperties;
    bodySemiBold?: React.CSSProperties;
    warning?: React.CSSProperties;
    addEditLink?: React.CSSProperties;
    smallText?: React.CSSProperties;
    bold?: React.CSSProperties;
  }
}

declare module '@material-ui/core/Button' {
  interface ButtonPropsVariantOverrides {
    main: true;
    submain: true;
  }
}

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyBold: true;
    sidebarBold: true;
    bodyRegular: true;
    disabledRegular: true;
    sidebarRegular: true;
    bodySemiBold: true;
    warning: true;
    addEditLink: true;
    smallText: true;
    bold: true;
  }
}

const font = "'Fjalla One', 'Open Sans', sans-serif";

const BaseTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0057B8',
    },
    secondary: {
      main: '#F2A900',
    },
    tertiary: {
      main: '#87A828',
    },
    success: {
      main: '#87A828',
    },
    error: {
      main: '#FF0000',
    },
    background: {
      default: '#FFFFFFF',
    },
    primaryLink: '#0057B8',
    primaryLinkHighlight: '#2B70E3',
    primaryHover: '#2B70E30D',
    secondaryHover: '#FFFFFF0D',
    primaryShadow: '#F2A900',
    secondaryShadow: '#F2A9000D',
    tertiaryHover: '#F2A900',
    tertiaryShadow: '#F2A900',
    primaryFontColor: '#FFFFFF',
    secondaryFontColor: ' #0057B8',
    extraColors: {
      black: '#2C2A29',
      darkGrey: '#999999',
      lightGrey: '#DDDDDD',
      offWhite: '#F8F8F8',
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: font,
    h1: {
      fontFamily: 'Fjalla One',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '32px',
      lineHeight: '40.22px',
    },
    h2: {
      fontFamily: 'Fjalla One',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '25.14px',
    },
    bodyBold: {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '21.79px',
    },
    sidebarBold: {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '24.51px',
    },
    bodyRegular: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '24.51px',
    },
    disabledRegular: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      lineHeight: '21.79px',
      color: '#999999',
    },
    bodySemiBold: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      lineHeight: '18.75px',
    },
    bold: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 700,
      fontStyle: 'bold',
      lineHeight: '18.75px',
    },
    sidebarRegular: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '24.51px',
    },
    warning: {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '21.79px',
      color: '#0057B8',
    },
    addEditLink: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#0057B8',
      cursor: 'pointer',
    },
    smallText: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: 'normal',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '21.79px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '21.79px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontSize: '14px',
          lineHeight: '21.79px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'main' },
          style: {
            color: '#F8F8F8',
            padding: '12px',
            backgroundColor: '#0057B8',
            borderRadius: 0,
            whiteSpace: 'nowrap',
            boxShadow: 'inset 0px -3px 0px 0px #F2A900',
            '&:hover': {
              backgroundColor: '#2B70E3',
            },
            '&.Mui-disabled': {
              backgroundColor: '#0057BB',
              opacity: 0.5,
              color: '#F8F8F8',
            },
          },
        },
        {
          props: { variant: 'submain' },
          style: {
            color: '#0057B8',
            backgroundColor: '#F8F8F8',
            borderRadius: 0,
            padding: '12px',
            whiteSpace: 'nowrap',
            boxShadow: 'inset 0px -3px 0px 0px #F2A900',
            '&:hover': {
              backgroundColor: '#FFFFFF',
            },
            '& .Mui-disabled': {
              backgroundColor: '#2B70E30D',
              color: '#F8F8F8',
            },
          },
        },
      ],
    },
  },
});

export default BaseTheme;
