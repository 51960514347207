import {
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PreviousNextButtons from '../Components/common/PreviousNextButtons';
import ReviewForm from '../Components/Review/ReviewForm';
import ElectricAPI from '../API/GlobalApi';
import UserDataContext from '../Context/UserDataContext';
import LoadingOverlay from '../Components/common/LoadingOverlay';
import SnackbarAlert from '../Components/common/SnackbarAlert';
import { useHistory } from 'react-router-dom';
interface IProps {
  requestType: string;
}

const Review: React.FC<IProps> = (props: IProps) => {
  const { requestType } = props;
  const theme = useTheme();
  const history = useHistory();
  const { state: userState } = useContext(UserDataContext);
  const [checkedRecaptcha, setCheckedRecaptcha] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));

  const handleRecaptchaClicked = () => {
    if (executeRecaptcha) {
      executeRecaptcha().then((token: string) => {
        if (token) {
          setCheckedRecaptcha(true);
        }
      });
    }
  };

  const handleNextClick = () => {
    setIsLoading(true);
    ElectricAPI.submitData(userState.userData)
      .then((result) => {
        if (result.success) {
          setIsLoading(false);
          history.push(`/${requestType}/success`);
        }
      })
      .catch((e) => {
        console.log('error', e);
        setShowAlert(true);
        setIsLoading(false);
      });
  };

  return (
    <Grid
      container
      item
      direction="column"
      spacing={2}
      style={{ marginLeft: isMobile ? '8px' : '0px' }}
    >
      <Grid item>
        <Typography variant="h1">REVIEW YOUR DETAILS</Typography>
        <Typography variant="bodyRegular">
          Please review the information below and ensure everything is accurate.
        </Typography>
      </Grid>
      <ReviewForm requestType={requestType} />
      <Grid
        container
        wrap="nowrap"
        justifyContent="flex-start"
        marginTop="30px"
        paddingLeft="16px"
      >
        <Grid item>
          <Checkbox
            checked={checkedRecaptcha}
            color="primary"
            onClick={handleRecaptchaClicked}
          />
        </Grid>

        <Grid item alignSelf="center">
          <Typography variant="sidebarRegular">I'm not a robot </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        wrap="nowrap"
        justifyContent="flex-start"
        marginTop="0px"
        paddingLeft="16px"
        paddingRight="16px"
      >
        <Typography variant="smallText">
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a>
        </Typography>
        <Typography variant="smallText">
          , <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </Typography>
      </Grid>
      <Grid item>
        <PreviousNextButtons
          nextDisabled={!checkedRecaptcha}
          clickNextHandler={handleNextClick}
        />
      </Grid>
      <LoadingOverlay isLoading={isLoading} />
      <SnackbarAlert
        open={showAlert}
        content="An issue occurred while submitting your request. Please try again or contact support."
        closeMessage={() => setShowAlert(false)}
        error={true}
      />
    </Grid>
  );
};

export default Review;
