import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ICustomerInformation } from '../Context/DataTypes';
import './CustomerInformation.css';
import PreviousNextButtons from '../Components/common/PreviousNextButtons';
import UserDataContext from '../Context/UserDataContext';
import { ContactTypes, ServiceRequestType } from '../Context/Enums';
import ContactInformationForm from '../Components/Forms/ContactInformationForm';

import LegalLandOwnerForm from '../Components/Forms/LegalLandOwnerForm';
import {
  isATCOExpeditorFormFilled,
  isLegalLandOwnerBreakerChangeFormFilled,
  isLegalLandOwnerFormFilled,
  isOtherContactFormFilled,
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
  validateSitId,
} from '../Components/helpers/util';
import SnackbarAlert from '../Components/common/SnackbarAlert';
import { useHistory } from 'react-router-dom';
import { UserDataActionType } from '../Context/UserDataStateReducer';

interface IProps {
  requestType: string;
}

const ContactInformation: React.FC<IProps> = (props: IProps) => {
  const { requestType } = props;
  const theme = useTheme();
  const history = useHistory();
  const lowestScreenSize = 1200;
  const isMobile = useMediaQuery(theme.breakpoints.down(lowestScreenSize));
  const { state: userState, dispatch } = useContext(UserDataContext);
  const [disableNext, setDisableNext] = useState(
    userState.userData.customerInfo.length > 0 ? false : true
  );
  const [showAlert, setShowAlert] = useState(false);

  const emptyCustomer = {
    index: 0,
    siteID: '',
    contactType: ContactTypes.PRIMARY_SITE_CONTACT,
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    emailAddress: '',
    streetAddress: '',
    city: '',
    postalCode: '',
  };

  const initialLandOwner =
    userState.userData.customerInfo.length > 0
      ? userState.userData.customerInfo[0]
      : {
          ...emptyCustomer,
          contactType: ContactTypes.LEGAL_LAND_OWNER,
        };

  const [legalLandOwner, setLegalLandOwner] = useState<ICustomerInformation>(
    initialLandOwner
  );

  const initialContacts =
    userState.userData.customerInfo.length > 1
      ? userState.userData.customerInfo.slice(1)
      : [];

  const [otherContacts, setOtherContacts] = useState<ICustomerInformation[]>(
    initialContacts
  );

  const validateEmptyFields = (customer: ICustomerInformation) => {
    if (customer.contactType === ContactTypes.LEGAL_LAND_OWNER) {
      if (requestType === ServiceRequestType.BREAKER_CHANGE) {
        return isLegalLandOwnerBreakerChangeFormFilled(customer);
      } else {
        return isLegalLandOwnerFormFilled(customer);
      }
    } else if (customer.contactType === ContactTypes.ATCO_EXPEDITOR) {
      return isATCOExpeditorFormFilled(customer);
    } else {
      return isOtherContactFormFilled(customer);
    }
  };

  const checkForFormErrors = (customer: ICustomerInformation) => {
    let errors = [];
    if (customer.contactType === ContactTypes.LEGAL_LAND_OWNER) {
      if (requestType === ServiceRequestType.BREAKER_CHANGE) {
        errors.push(validateSitId(customer.siteID));
      }
      errors.push(validatePostalCode(customer.postalCode));
    }

    const phoneErrors = validatePhoneNumber(customer.phoneNumber);
    const emailErrors = errors.push(validateEmail(customer.emailAddress));
    errors = [...errors, phoneErrors, emailErrors];
    return errors.find((error) => error === true);
  };

  const enableDisableNext = (customer: ICustomerInformation) => {
    const isNotEmpty = validateEmptyFields(customer);
    const errors = checkForFormErrors(customer);
    if (isNotEmpty && !errors) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  };

  const checkOtherContacts = () => {
    const result: any = [];
    otherContacts.forEach((customer) => {
      const isNotEmpty = validateEmptyFields(customer);
      const errors = checkForFormErrors(customer);
      if (isNotEmpty && !errors) {
        result.push(false);
      } else {
        result.push(true);
      }
    });

    return result.find((res: boolean) => res === true);
  };

  const handleLandOwner = (customer: ICustomerInformation) => {
    enableDisableNext(customer);
    setLegalLandOwner(customer);
  };

  const handleCustomerChange = (
    customer: ICustomerInformation,
    index: number
  ) => {
    let tempCopy = [...otherContacts];
    tempCopy[index] = { ...customer, index: index };
    setOtherContacts(tempCopy);
  };

  const handleNextClick = () => {
    const hasError = checkOtherContacts();

    if (hasError) {
      setShowAlert(true);
    } else {
      dispatch({
        type: UserDataActionType.SetCustomersInfo,
        payload: { customersInfo: [legalLandOwner, ...otherContacts] },
      });

      history.push(`/request-type/${requestType}/review`);
    }
  };

  const handleAddClick = () => {
    setOtherContacts([
      ...otherContacts,
      { ...emptyCustomer, index: otherContacts.length + 1 },
    ]);
  };

  const handleRemoveClick = (index: number) => {
    otherContacts.splice(index, 1);
    setOtherContacts([...otherContacts]);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      style={{ flexGrow: 1, marginLeft: isMobile ? '8px' : '0px' }}
      spacing={2}
    >
      <Grid item>
        <Typography variant="h1">Land Owner Information</Typography>
      </Grid>
      <Grid item paddingTop="0">
        <Typography variant="bodyRegular">
          The legal land owner is required to complete the application
        </Typography>
      </Grid>
      <Grid item>
        <LegalLandOwnerForm
          landOwner={legalLandOwner}
          handleLandOwner={handleLandOwner}
          requestType={requestType}
        />
      </Grid>
      <Grid item>
        {otherContacts.map((customer: ICustomerInformation, index: number) => (
          <Grid item container direction="column" spacing={1}>
            <Grid
              item
              container
              wrap="nowrap"
              justifyContent="flex-end"
              alignItems="center"
              marginTop="8px"
            >
              <Grid item container>
                <Divider style={{ color: '#C4C4C4', width: '99%' }} />
              </Grid>
              <Grid item flexShrink={0}>
                <Typography
                  variant="addEditLink"
                  onClick={() => handleRemoveClick(index)}
                >
                  - Remove contact
                </Typography>
              </Grid>
            </Grid>

            <ContactInformationForm
              key={customer.index}
              customer={customer}
              handleCustomerChange={handleCustomerChange}
              requestType={requestType}
              index={index}
              setHasError={setDisableNext}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Typography variant="addEditLink" onClick={handleAddClick}>
          + Add another contact
        </Typography>
        <SnackbarAlert
          open={showAlert}
          content="Please fill out all required fields."
          closeMessage={() => setShowAlert(false)}
          error={true}
        />
      </Grid>
      <PreviousNextButtons
        nextDisabled={disableNext}
        clickNextHandler={handleNextClick}
      />
    </Grid>
  );
};

export default ContactInformation;
